import {Component, Input, OnInit} from '@angular/core';
import {AdminPositionMatchingConfigDisplayData, PositionProfessionFieldRelationData} from "../../../generated/data";
import {ConfirmDialogComponent} from "../../../utils/confirm-dialog/confirm-dialog.component";
import {AdminMatchingResource} from "../../../generated/resources";
import {SideSheetService} from "../../../utils/side-sheet/side-sheet.service";
import {MatDialog} from "@angular/material/dialog";
import {SpecialTargetingFiltersComponent} from "../special-targeting-filters/special-targeting-filters.component";
import {
  ProfessionSelectionSheetComponent
} from "../../../matching/profession-selection-sheet/profession-selection-sheet.component";
import {PrimeTalentFiltersComponent} from "../prime-talent-filters/prime-talent-filters.component";
import {SideSheetRef} from "../../../utils/side-sheet/sideSheetRef";
import {
  ProfessionFieldConfigSheetComponent
} from "../../../matching/profession-fields-relations-table/profession-field-config-sheet.component";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-position-details-matching',
  templateUrl: './position-details-matching.component.html',
  styleUrls: ['./position-details-matching.component.scss']
})
export class PositionDetailsMatchingComponent implements OnInit {

  @Input() positionId: number;

  config: AdminPositionMatchingConfigDisplayData

  constructor(private adminMatchingResource: AdminMatchingResource, private sheetService: SideSheetService, private dialog: MatDialog, private sideSheetRef: SideSheetRef) {
  }

  ngOnInit(): void {
    this.load();
  }

  loading = false;

  load() {
    this.loading = true;

    this.adminMatchingResource.getPositionMatchingConfig(this.positionId).then(res => {
      this.config = res;
      this.loading = false;
      this.professionFieldDataSource.data = res.configData.positionProfessionFieldRelations
    })
  }

  openEditProfessionSheet() {
    let comp = this.sheetService.openOverlay(ProfessionSelectionSheetComponent, 'Medium')
    comp.instance.config = this.config.configData;
    comp.instance.position = this.config.position

    comp.instance.sideSheetRef.sheetClosed.subscribe(updatedConfig => {
        if (updatedConfig) this.config.configData = updatedConfig;
    })
  }


  professionFieldDataSource = new MatTableDataSource<PositionProfessionFieldRelationData>();
  openEditProfessionFieldSheet(){
    let comp = this.sheetService.openOverlay(ProfessionFieldConfigSheetComponent, 'Medium')
    comp.instance.config = this.config.configData;
    comp.instance.position = this.config.position

    comp.instance.sideSheetRef.sheetClosed.subscribe(updatedConfig => {
      if (updatedConfig){
        this.config.configData = updatedConfig;
        //necessary for template refresh
        this.professionFieldDataSource.data = updatedConfig.professionFieldRelations;
      }
    })

  }

  saveAndRematch(){
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminMatchingResource.updatePositionMatchingConfigAndInitiateMatching(this.config.configData, this.positionId),
        title: `Matching-Config speichern`,
        message: `Deine Konfiguration wird gespeichert. Im Anschluss wird das Matching der Stelle wiederholt. Die Stelle muss hierfür einer Buchung angehören.`,
        confirmButtonText: 'Speichern',
        confirmButtonColor: 'primary'
      },
      panelClass: 'custom-dialog-container'
    });

    let sub = dialogRef.afterClosed().subscribe(result => {
      if (result) this.config.state = 'Active';
      sub.unsubscribe()
    });
  }


  deactivatePosition() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        onConfirm: () => this.adminMatchingResource.deactivateMatching(this.positionId),
        title: `Stelle deaktivieren`,
        message: `Möchtest Du die Stelle wirklich deaktivieren und alle Stellenvorschläge zu dieser Stelle zurückziehen? Außerdem wird sie aus ihrer Buchung entfernt.`,
        confirmButtonText: 'Deaktivieren',
        confirmButtonColor: 'primary'
      },
      panelClass: 'custom-dialog-container'
    });

    let sub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.config.state = 'Inactive'
      }
      sub.unsubscribe()
    });
  }

  openPrimeFilter() {
    let ref = this.sheetService.openOverlay(PrimeTalentFiltersComponent, 'Large')
    ref.instance.positionId = this.positionId

    ref.instance.sideSheetRef.sheetClosed.subscribe(() => {
      this.load()
    })
  }

  openSpecialTargeting() {
    let ref = this.sheetService.openOverlay(SpecialTargetingFiltersComponent, 'Large')
    ref.instance.positionId = this.positionId

    ref.instance.sideSheetRef.sheetClosed.subscribe(() => {
      this.load()
    })
  }

  canSave() {
    return this.config?.configData?.profession != null
      && this.config?.configData?.positionProfessionFieldRelations?.length > 0
      && this.locationValid()
  }

  locationValid() {
    return this.config?.location?.mode == "Nationwide"
        || this.config?.location?.mode == "Worldwide"
        || (this.config?.location?.mode == "SpecificCities" && this.config?.location?.cityIds?.length > 0)
        || (this.config?.location?.mode == "SpecificStates" && this.config?.location?.federalStateIds?.length > 0)
  }
}
