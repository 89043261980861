<dialog-header [dialogHeadline]="'Unternehmensinformationen'"></dialog-header>

<mat-dialog-content fxLayout="column">
    <div class="container">
        <h2>Unternehmen</h2>
        <div class="grid-view">
            <invoice-data-display label="Unternehmensname" [data]="adminInvoiceData.companyName" alternative="Kein Name"></invoice-data-display>
            <invoice-data-display label="Unternehmens-ID" [data]="toString(adminInvoiceData.companyId)" alternative="Keine ID"></invoice-data-display>

            <invoice-data-display label="Unternehmens Anschrift" [data]="adminInvoiceData.companyAddress" alternative="Keine Anschrift"></invoice-data-display>
            <invoice-data-display label="E-Mail" [data]="adminInvoiceData.companyEmail" alternative="Keine E-Mail"></invoice-data-display>

            <invoice-data-display label="Rechnungsempfänger" [data]="adminInvoiceData.invoiceRecipient" alternative="Kein Rechnungsempfänger"></invoice-data-display>
            <invoice-data-display label="Key Accounter" [data]="adminInvoiceData.keyAccounter" alternative="Kein Key Accounter"></invoice-data-display>

            <invoice-data-display label="Zahlungsinformation" [data]="adminInvoiceData.additionalPurchaseInformation" alternative="Keine Information"></invoice-data-display>
            <invoice-data-display label="Steuernummer" [data]="adminInvoiceData.taxNumber" alternative="Keine Steuernummer"></invoice-data-display>

            <invoice-data-display label="Preismodell" [data]="adminInvoiceData.companyPricingMode | companyPricingMode" alternative="Kein Preismodell"></invoice-data-display>
        </div>
        <invoice-data-display label="Kommentar" [data]="adminInvoiceData?.comment" alternative="Kein Kommentar"></invoice-data-display>


        <h2>Rechnung:
            <span *ngIf="adminInvoiceData.invoiceNumber && adminInvoiceData.invoiceNumber !== 'null';"
                  style="cursor: copy;"
                  (click)="copyValueToClipboard(adminInvoiceData.invoiceNumber)">
                      {{ adminInvoiceData.invoiceNumber }}
            </span>
            <span style="color: gray; cursor: default;" *ngIf="!(adminInvoiceData.invoiceNumber && adminInvoiceData.invoiceNumber !== 'null')">Keine Nummer</span>
        </h2>


        <div class="grid-view">
            <invoice-data-display label="Leistungsdatum" [data]="adminInvoiceData.date | date:'dd.MM.yyyy'" alternative="Kein Datum"></invoice-data-display>
            <invoice-data-display label="Datenbank Rechnungs-ID" [data]="toString(adminInvoiceData.invoiceId)" alternative="Keine ID"></invoice-data-display>

            <invoice-data-display label="Talent" [data]="adminInvoiceData.talentName" alternative="kein Talent" [onButtonClick]="openTalentProfile"></invoice-data-display>
            <invoice-data-display label="Stelle" [data]="adminInvoiceData.positionName" alternative="keine Stelle" [onButtonClick]="openPositionDetailsDialog"></invoice-data-display>

            <invoice-data-display label="Talent-ID" [data]="toString(adminInvoiceData.talentId)" alternative="keine Talent-ID"></invoice-data-display>
            <div fxLayout="column" fxLayoutAlign="start start" class="custom-information">
                <div class="title">Bewerbungsanzahl</div>
                <div class="value-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <div class="value" [ngClass]="{ 'no-recipient': !adminInvoiceData.totalApplications }">
                        {{  adminInvoiceData.totalApplications
                        ? adminInvoiceData.applications + '/' + adminInvoiceData.totalApplications
                        : 'Keine Daten verfügbar' }}</div>
                </div>
            </div>
        </div>
        <invoice-data-display label="Rechnungs-Kommentar" [data]="adminInvoiceData.invoiceComment" alternative="kein Rechnungs-Kommentar"></invoice-data-display>

        <div class="grid-view" [formGroup]="formGroup" *ngIf="formGroup && ['InvoiceHiringValue','InvoiceHiringPercentage'].includes(adminInvoiceData.dtype)">
            <mat-form-field class="hiring-datepicker">
                <mat-label>Unterschriftsdatum</mat-label>
                <input matInput [matDatepicker]="pickerSignature" formControlName="signingDate">
                <mat-hint>DD.MM.YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="pickerSignature"></mat-datepicker-toggle>
                <mat-datepicker #pickerSignature></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="hiring-datepicker">
                <mat-label>Einstiegsdatum</mat-label>
                <input matInput [matDatepicker]="pickerStart" formControlName="startingDate">
                <mat-hint>DD.MM.YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                <mat-datepicker #pickerStart></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="width: 100%; margin-top: 0.5rem">
                <mat-label>Gehalt in Euro pro Jahr</mat-label>
                <input matInput formControlName="salaryText">
            </mat-form-field>

        </div>
        <mat-form-field class="width-100" [formGroup]="formGroup" *ngIf="formGroup && ['InvoiceHiringValue','InvoiceHiringPercentage'].includes(adminInvoiceData.dtype)">
            <mat-label>Admin Notizen</mat-label>
            <textarea matInput cdkTextareaAutosize
                      cdkAutosizeMinRows="1"
                      cdkAutosizeMaxRows="15" formControlName="notes"></textarea>
        </mat-form-field>

        <div class="width-invoice-container">
            <div></div>
            <div fxLayout="column" fxLayoutAlign="end end" class="custom-information">
                <div class="title">Rechnungsbetrag</div>
                <div class="value amount">{{ adminInvoiceData.amount }}€</div>
            </div>
        </div>
    </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <div class="container">
        <button mat-flat-button color="primary" class="save" (click)="updateHiring()" *ngIf="formGroup && ['InvoiceHiringValue', 'InvoiceHiringPercentage'].includes(adminInvoiceData.dtype)">
            Speichern
        </button>
    </div>
</mat-dialog-actions>