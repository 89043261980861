import {Component, OnInit} from '@angular/core';
import {BookingCreateOrUpdateData, BookingData} from "../../generated/data";
import {AdminBookingResource} from "../../generated/resources";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-create-booking-dialog',
  templateUrl: './create-or-update-booking-side-sheet.component.html',
  styleUrls: ['./create-or-update-booking-side-sheet.component.scss']
})
export class CreateOrUpdateBookingSideSheetComponent implements OnInit {
  booking?: BookingData;
  loading: boolean = false
  bookingForm: FormGroup

  constructor(
    public sideSheetRef: SideSheetRef,
    private adminBookingResource: AdminBookingResource,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initBookingData()
    this.initForm()
  }

  initForm() {
    this.bookingForm = this.fb.group({
      title: [this.booking?.title, [Validators.required, Validators.pattern(/\S/)]], // must include a non-whitespace character
      size: [this.booking?.size ?? 15, Validators.required],
      resupply: [this.booking?.resupply ?? 0, Validators.required],
      price: [this.booking?.price ?? 1500, Validators.required],
      companyId: [this.booking?.company?.id, Validators.required],
      startDate: [null]
    });
  }

  close(result?: BookingData) {
    this.sideSheetRef.sheetClosed.next(result)
  }

  initBookingData() {
    if (!this.booking) {
      this.booking = <BookingData>{positions: []};
    }
  }

  createOrUpdateBooking() {
    this.loading = true

    const booking: BookingCreateOrUpdateData = {
      id: this.booking?.id,
        companyId: this.bookingForm.value.companyId,
      title: this.bookingForm.controls.title.value,
      price: this.bookingForm.controls.price.value,
      size: this.bookingForm.controls.size.value,
      resupply: this.bookingForm.controls.resupply.value,
    }

    this.adminBookingResource.createOrUpdateBooking(booking, {dateFrom: this.bookingForm.controls.startDate.value}).then(result => {
      this.close(result)
    });
  }
}
