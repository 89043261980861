<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="10px" class="filter-wrapper">
                <mat-form-field>
                    <mat-label>Zeitspanne</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input [formControl]="startDate" matStartDate placeholder="Eingestellt ab">
                        <input [formControl]="endDate" matEndDate placeholder="Eingestellt bis">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <searchable-company-dropdown
                        placeholder="Firma"
                        [multiple]="false"
                        [required]="false"
                        [control]="companyControl">
                </searchable-company-dropdown>

                <key-accounter-dropdown
                    placeholder="KeyAccounter"
                    [multiple]="true"
                    [required]="false"
                    appearance="outline"
                    [control]="keyAccounterControl">
                </key-accounter-dropdown>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center">
                <loading-button
                        matTooltip="Aktualisieren"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        (clickEvent)="loadData()">
                    <i class="mdi mdi-refresh dark filter-color"></i>
                </loading-button>
            </div>

        </div>
    </div>

    <loading-spinner [loadingBool]="loading">

        <table mat-table #invoiceStatisticsTable class="width-100"
               [dataSource]="dataSource" multiTemplateDataRows>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions" style="width: 60px">&nbsp;</th>
                <td mat-cell *matCellDef="let element" style="background-color: #f3f3f3">
                    <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef>Unternehmen</th>
                <td mat-cell *matCellDef="let element" style="background-color: #f3f3f3"> {{element.company.name}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="comment">
                <th mat-header-cell *matHeaderCellDef>Pricing Mode</th>
                <td mat-cell *matCellDef="let element" style="background-color: #f3f3f3">{{ element.companyPricingMode }}</td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="keyaccounter">
                <th mat-header-cell *matHeaderCellDef>KeyAccounter</th>
                <td mat-cell *matCellDef="let element" style="background-color: #f3f3f3"> {{element.keyAccounter?.name}} </td>
                <td mat-footer-cell *matFooterCellDef> </td>
            </ng-container>

            <ng-container matColumnDef="invoices">
                <th mat-header-cell *matHeaderCellDef>Rechnungen</th>
                <td mat-cell *matCellDef="let element" style="background-color: #f3f3f3"> {{element.invoices.length}}</td>
                <td mat-footer-cell *matFooterCellDef>{{getTotalInvoices()}} </td>
            </ng-container>

            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef style="text-align: right">Gesamt Wert</th>
                <td mat-cell *matCellDef="let element" style="text-align: right; background-color: #f3f3f3"> {{getInvoicesTotalAmount(element) | currency: 'EUR':'symbol':undefined:'de-DE' }}</td>
                <td mat-footer-cell *matFooterCellDef style="text-align: right"> {{getTotalCost() | currency: 'EUR':'symbol':undefined:'de-DE'}} </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                    <div class="element-detail"
                         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div *ngFor="let invoice of element.invoices" fxLayout="row" fxLayoutAlign="space-between center" class="invoice">
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="32px">
                            <div>{{invoice.date | date: "dd.MM.yy"}}</div>
                            <div>Rechnung: {{invoice.id}}</div>
                            <div style="width: 400px">{{invoice.dtype | invoice}}
                            <span *ngIf="invoice.dtype == 'InvoiceHiringValue'">(Festpreis)</span>
                            <span *ngIf="invoice.dtype == 'InvoiceHiringPercentage'">({{invoice.percentageHiring}}% Bruttojahresgehalt)</span>
                            <span *ngIf="invoice.dtype == 'InvoiceDiscount'"></span>
                            <span *ngIf="invoice.dtype == 'InvoiceBooking'">({{invoice.booking.applications.length}}/{{invoice.booking.size}} {{invoice.booking.state | bookingState}})</span>

                               <button mat-icon-button (click)="openCompanyDetailsDialog(invoice)">
                                    <i class="mdi mdi-information"></i>
                                </button>
                                <button style="margin-left: 10px" mat-icon-button (click)="editInvoice(invoice)">
                                    <i class="mdi mdi-pencil"></i>
                                </button>
                            </div>
                        </div>

<!--                        <div>{{invoice.applicationId}}</div>-->
<!--                        <div>{{invoice.bookingId}}</div>-->
                        <div>{{invoice.amount | currency: 'EUR':'symbol':undefined:'de-DE'}}</div>
                    </div>
                    </div>
                </td>
                <td mat-footer-cell *matFooterCellDef> Total </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="element-row"
                [class.expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <div *ngIf="dataSource.length == 0" class="nothing-found">Es wurde nichts gefunden</div>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>

