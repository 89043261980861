import {Injectable} from '@angular/core';
import {BookingData} from "../generated/data";
import {AdminBookingResource} from "../generated/resources";
import {ConfirmDialogComponent} from "../utils/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SideSheetService} from "../utils/side-sheet/side-sheet.service";
import {
  CreateOrUpdateInvoiceSideSheetComponent
} from "../invoices/create-or-update-invoice-side-sheet/create-or-update-invoice-side-sheet.component";
import {UtilityService} from "../utils/utility.service";

@Injectable({
  providedIn: 'root'
})
export class BookingService {
    constructor(
        private adminBookingResource: AdminBookingResource,
        private dialog: MatDialog,
        private sideSheetService: SideSheetService,
        private utilityService: UtilityService
    ) {}

    closeBooking(bookingData: BookingData) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                onConfirm: () => this.adminBookingResource.closeBooking(bookingData.id),
                confirmButtonText: "Buchung schließen",
                message: "Willst du die Buchung wirklich schließen? Danach kannst du sie nicht wieder reaktivieren!",
                title: "Buchung schließen"
            },
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                bookingData.state = "Closed"
                this.utilityService.showSnackBar("Buchung geschlossen!")
            }
        })
    }

    createInvoice(bookingData: BookingData) {
        const comp = this.sideSheetService.openOverlay(CreateOrUpdateInvoiceSideSheetComponent, 'Small')
        comp.instance.bookingData = bookingData

        comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
            if(result && bookingData.companyPricing == 'Booking') {
                this.utilityService.showSnackBar("Rechnung wurde erstellt!")
            }
        })
    }
}
