import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {MatDialogRef} from "@angular/material/dialog";
import {saveAs} from "file-saver/FileSaver"
import {FileService} from "../../services/file.service";
import {AdminEmploymentPositionResource} from '../../generated/resources';

@Component({
  selector: 'app-file-upload-dialog',
  templateUrl: './file-upload-dialog.component.html',
  styleUrls: ['./file-upload-dialog.component.css']
})
export class FileUploadDialogComponent implements OnInit {

  fileEvent;
  file = new FormControl(null, this.formControlValidator());
  name;
  downloading: boolean = false;
  saving: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<FileUploadDialogComponent>,
    private adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private http: HttpClient,
    private fileService: FileService
  ) {
  }

  ngOnInit() {
  }

  formControlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return {isNull: true};
      }
      if (control.value[0].size >= 50000000) {
        return {sizeTooBig: true};
      }

      return null;
    }
  }

  isValidFile() {
    return (this.file.valid == true);
  }

  set(fileEvent: Event) {
    this.fileEvent = fileEvent;
  }

  save() {

    this.saving = true;

    let file = this.file.value[0];

    this.name = file.name;
    this.fileService.uploadPositionExcelFile(file).subscribe(
      (response) => {
        if (response.size > 0) {
          saveAs(response, "error-report.xlsx");
        }
        this.dialogRef.close(true);
        this.saving = false;
      }
    )
  }

  downloadExcel() {
    this.downloading = true;
    window.open('../../assets/files/import-example.xlsx', '_blank');
    this.downloading = false;
  }

  downloadReadMe() {
    this.downloading = true;
    window.open('../../assets/files/ReadMeExcelImport.txt', '_blank');
    this.downloading = false;
  }

  close() {
    this.dialogRef.close(false);
  }

  getFileName() {
    if (this.file.value) return this.file.value.toString().substring(0, 20) + '...';
    else return 'Upload';
  }
}
