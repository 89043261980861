import {Component, ComponentRef, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {BookingData, BookingFilterData, BookingState} from "../../../generated/data";
import {AdminBookingResource, AdminCompanyResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";
import {MatFormFieldAppearance} from "@angular/material/form-field";
import {
  CreateOrUpdateBookingSideSheetComponent
} from "../../../bookings/create-booking-dialog/create-or-update-booking-side-sheet.component";
import {SideSheetService} from "../../side-sheet/side-sheet.service";

@Component({
  selector: 'searchable-booking-dropdown',
  templateUrl: './searchable-booking-dropdown.component.html',
  styleUrls: ['./searchable-booking-dropdown.component.scss']
})
export class SearchableBookingDropdownComponent implements OnInit {

  @Input() placeholder: string = ''
  @Input() appearance: MatFormFieldAppearance = 'outline'
  @Input() control: AbstractControl
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() disabled: boolean = false
  @Input() companyId?: number
  @Input() states?: BookingState[]
  @Input() hint?: string;

  bookings: BookingData[] = [];

  filterControl: FormControl = new FormControl()

  constructor(
    private adminBookingResource: AdminBookingResource,
    private dialogService: SideSheetService,
    private companyResource: AdminCompanyResource,
  ) {
  }

  ngOnInit() {
    this.loadBookings();

    this.filterControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(newValue => {
        this.loadBookings()
      });
  }


  loadBookings() {
    const filterData: BookingFilterData = {
      partialTitle: this.filterControl.value,
      companyId: this.companyId,
      states: this.states,
    };

    this.adminBookingResource.getFilteredBookings(filterData).then(bookings => {
        this.bookings = bookings.content;
      }
    )
  }

  createNewBooking() {
    const comp: ComponentRef<CreateOrUpdateBookingSideSheetComponent> = this.dialogService.openOverlay(CreateOrUpdateBookingSideSheetComponent, 'Small')
    comp.instance.booking = <BookingData>{company: {id: this.companyId, name: ""}, positions: []};

    this.companyResource.getCompany(this.companyId).then(company => {
      comp.instance.booking.company = {id: this.companyId, name: company.name};
    });

    const subscription = comp.instance.sideSheetRef.sheetClosed.subscribe((res: BookingData) => {
      if (res) {
        this.loadBookings();
        this.control.setValue(res.id);
      }
      subscription.unsubscribe();
    });
  }
}
