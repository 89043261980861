<dialog-header [dialogHeadline]="'Einstellungsdetails'"></dialog-header>

<div class="content-wrapper">
    <div class="height-100 width-100" fxLayout="column" fxLayoutAlign="start start" [formGroup]="formGroup" *ngIf="formGroup">

        <div class="width-100" fxLayout="row" fxLayoutAlign="end center">
            <loading-button buttonClass="mat-primary" [loadingBool]="updateHiringLoading" (clickEvent)="updateHiring()">
                <i class="mdi mdi-content-save"></i>
            </loading-button>
        </div>

        <div class="width-100">
            <h3>Allgemein</h3>
            <div fxLayout="column" fxLayoutAlign="start start" class="custom-information">
                <div class="title">Talent</div>
                <div class="value-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <div class="value">{{hiringData.talentName}}</div>
                    <button mat-icon-button (click)="openTalentProfile()"><i class="mdi mdi-information-outline"></i></button>
                </div>
            </div>

            <div fxLayout="column" fxLayoutAlign="start start" class="custom-information">
                <div class="title">Stelle</div>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
                    <div class="value">{{hiringData.positionTitle}}</div>
                    <button mat-icon-button (click)="openPositionDetailsDialog()"><i class="mdi mdi-information-outline"></i></button>
                </div>
            </div>
        </div>

        <div class="width-100">
            <h3>Unterschrifts- und Einstiegsdatum</h3>

            <div class="width-100" fxLayout="row" fxLayoutAlign="space-between start">
                <mat-form-field class="hiring-datepicker">
                    <mat-label>Unterschriftsdatum</mat-label>
                    <input matInput [matDatepicker]="pickerSignature" formControlName="signingDate">
                    <mat-hint>DD.MM.YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="pickerSignature"></mat-datepicker-toggle>
                    <mat-datepicker #pickerSignature></mat-datepicker>
                </mat-form-field>

                <mat-form-field class="hiring-datepicker">
                    <mat-label>Einstiegsdatum</mat-label>
                    <input matInput [matDatepicker]="pickerStart" formControlName="startingDate">
                    <mat-hint>DD.MM.YYYY</mat-hint>
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="salary-wrapper">

            <h3>Gehaltsangaben</h3>

<!--            <mat-radio-group formControlName="salaryState" required fxLayout="column">-->
<!--                <mat-radio-button value="SalaryUnknown">Ich weiß das Gehalt noch nicht / später angeben</mat-radio-button>-->
<!--                <mat-radio-button style="margin: 0.5rem 0" value="SalaryGiven">Ich kenne das Gehalt</mat-radio-button>-->
<!--            </mat-radio-group>-->

            <mat-form-field style="width: 100%; margin-top: 0.5rem">
                <mat-label>Gehalt in Euro pro Jahr</mat-label>
                <input matInput formControlName="salaryText">
            </mat-form-field>
        </div>

        <div class="adminNotes-wrapper">

            <h3>Notizen / Kommentare</h3>

            <mat-form-field class="width-100">
                <mat-label>Admin Notizen</mat-label>
                <textarea matInput cdkTextareaAutosize
                          cdkAutosizeMinRows="1"
                          cdkAutosizeMaxRows="15" formControlName="notes"></textarea>
            </mat-form-field>
        </div>

    </div>
</div>