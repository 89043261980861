import {Component, OnInit} from '@angular/core';
import {
    AdminTalentFeedPositionRelationData,
    AdminTalentLogEntryData,
    AdminTalentLogEntryFilterData,
    AdminTalentPositionRelationData,
    CareerEventData,
    CompleteTalentProfileData,
    LabelData,
    PositionRelationFilterData,
    ProfessionFieldPreferenceData,
    TalentDeactivationData,
    TalentMatchingReportData
} from "../../generated/data";
import {ActivatedRoute} from "@angular/router";
import {
    AdminCareerEventResource,
    AdminControllingResource,
    AdminEmploymentPositionResource,
    AdminMatchingCacheResource,
    AdminPrimePositionRecommendationResource,
    AdminResource,
    AdminTalentResource,
    ProfessionFieldPreferenceResource,
} from "../../generated/resources";
import {DomSanitizer, Title} from "@angular/platform-browser";
import {UtilityService} from "../../utils/utility.service";
import {MatDialog} from "@angular/material/dialog";
import {CareerEventPipe} from "../../utils/pipes/career-event.pipe";
import {FileService} from "../../services/file.service";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {ConfirmDialogComponent} from "../../utils/confirm-dialog/confirm-dialog.component";
import {CareerEventDetailsDialogComponent} from "../talent-profile/career-event-details-dialog/career-event-details-dialog.component";
import {EditImageDialogComponent} from "../../utils/edit-image-dialog/edit-image-dialog.component";
import {MatchingAnalyzeSheetComponentComponent} from "../../matching/matching-optimization/matching-analyze-sheet-component/matching-analyze-sheet-component.component";
import {PositionDetailsDialogComponent} from "../../positions/position-details-dialog/position-details-dialog.component";
import {MarkPrimePositionAsUnsuitableDialogComponent} from "../mark-prime-position-as-unsuitable-dialog/mark-prime-position-as-unsuitable-dialog.component";
import {SelectionModel} from '@angular/cdk/collections';
import {FormControl} from "@angular/forms";
import {MatTabChangeEvent} from "@angular/material/tabs";
import {CreatePositionRecommendationsDialogComponent} from "../create-position-recommendations-dialog/create-position-recommendations-dialog.component";
import {MatTableDataSource} from "@angular/material/table";
import {Subject} from "rxjs";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";

@Component({
    selector: 'app-talent-profile-dialog',
    templateUrl: './talent-profile-dialog.component.html',
    styleUrls: ['./talent-profile-dialog.component.scss']
})
export class TalentProfileDialogComponent implements OnInit {
    talent: CompleteTalentProfileData;
    talentBlacklistedCities: LabelData[];
    totalElements: number = 0;
    talentId: number = 0;
    tabIndex: number = 0;
    loadingCalls: boolean = true

    positionRelationFilterData = new PositionRelationFilterData()

    displayedColumnsPrime: string[] = ['select', 'prime', 'position', 'company', 'matchReceived', 'state', 'mailSent', 'notReachedMailSent', 'action'];
    primePositionsData: AdminTalentPositionRelationData[] = []
    talentPositionRelationDataSource = new MatTableDataSource()
    onlySuitablePrimePositions: boolean = false

    cacheDataSource: TalentMatchingReportData[] = [];
    cachedMatchesLoading: boolean = false;

    careerEvents: CareerEventData[];
    displayedCareerEventColumns = ['type', 'title', 'academicTitle', 'study', 'endDate', 'mark', 'actions',];
    displayedColumnsOrders = ['date', 'type', 'actions'];
    displayedColumnsCalls = [
        'date',
        'type',
        'outcome',
        'note',
        'actions'];
    displayedLogEntryColumns = ['occurred', 'logName', 'message']

    talentPositionRelations: AdminTalentFeedPositionRelationData[] = []
    talentProfessionFieldPreferences: ProfessionFieldPreferenceData[];
    professionGroupPreferencesDisplayedColumns = ['groupTitle', 'preferenceState'];

    supervisedByChanged: Subject<boolean> = new Subject()


    loading: boolean = false;
    sendingPrimePositionNotification: boolean = false;
    loadingPrime: boolean = true;
    savingContent: boolean = false;
    adminNote: string = '';

    cvFile: Blob = null;

    profilePicture: any;
    timeStamp;

    currentlyMatching: boolean = false;
    loadingDeactivateTalent: boolean = false;

    logEntries: AdminTalentLogEntryData[] = []

    loadingLogs = true

    logDateFrom?: Date = null
    logDateTo?: Date = null

    applicationCheckbox: FormControl = new FormControl(true)
    coachingCheckbox: FormControl = new FormControl(true)
    onAndOffBoardingCheckbox: FormControl = new FormControl(true)
    profileChangeCheckbox: FormControl = new FormControl(true)
    othersCheckbox: FormControl = new FormControl(true)


    constructor(
        private route: ActivatedRoute,
        private adminTalentResource: AdminTalentResource,
        private titleService: Title,
        private adminCareerEventResource: AdminCareerEventResource,
        private professionFieldPreferenceResource: ProfessionFieldPreferenceResource,
        private utils: UtilityService,
        private dialog: MatDialog,
        private careerTypePipe: CareerEventPipe,
        private adminResource: AdminResource,
        private utilityService: UtilityService,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        private sideSheetService: SideSheetService,
        private sideSheetRef: SideSheetRef,
        private adminControllingResource: AdminControllingResource,
        private adminEmploymentPositionResource: AdminEmploymentPositionResource,
        private adminPrimePositionRecommendationResource: AdminPrimePositionRecommendationResource,
        private adminMatchingCacheResource: AdminMatchingCacheResource,
    ) {
        this.route.params.subscribe(params => {
                this.talentId = Number(params.id);

                if (!isNaN(this.talentId))
                    this.loadProfile(this.talentId)
            }
        );
    }

    getCVFile() {
        this.fileService.getCV(this.talentId).subscribe((cv) => {
            this.cvFile = cv
        })
    }

    ngOnInit() {
        this.talentPositionRelationDataSource.filterPredicate = (e: AdminTalentPositionRelationData, filter: string) => e.position.title.toLocaleLowerCase().indexOf(filter) != -1;

        this.positionRelationFilterData.talentId = this.talentId
        this.loadProfile(this.talentId)
        this.loadCareerEvents();
        this.loadProfilePicture();
        this.loadPositionMatchesFromTalentCache();

        this.professionFieldPreferenceResource.getProfessionFieldPreferencesForTalentAsAdmin(this.talentId).then(result => {
            if (result && result.length > 0) this.talentProfessionFieldPreferences = result
            else this.talentProfessionFieldPreferences = [];
        })

        this.adminResource.getTalentNote(this.talentId).then(
            (noteJson) => this.adminNote = noteJson.value
        );
    }

    // Temporary Lazy Loading Fix
    // TODO: Refactor this using <ng-template matTabContent> with self-loading components
    onTabChange(event: MatTabChangeEvent) {
        switch (event.index) {
            case 1:
                if (this.cvFile == null) this.getCVFile();
                break;
            case 3:
                if (this.talentPositionRelations.length == 0) {
                    this.loadPositionMatchesFromTalentCache();
                }
                break;
            case 4:
                if(this.primePositionsData.length == 0) this.getPrimePositions();
                break;
            case 5:
                if (this.logEntries.length == 0) {
                    this.loadLogEntries();
                }
                break;
        }
    }

    loadPositionMatchesFromTalentCache() {
        if (this.cachedMatchesLoading) return
        this.cachedMatchesLoading = true;
        this.adminMatchingCacheResource.getPositionsInTalentMatchingCache(this.talentId)
            .then(data => {
                // this.cacheDataSource = data.filter(m => !this.talentPositionRelations.map(e => e.position.id).includes(m.matchedPosition.id));
                this.cacheDataSource = data;
                this.cachedMatchesLoading = false;
            })
    }

    loadCareerEvents() {
        this.adminCareerEventResource.getTalentCareerEvents(this.talentId).then(result => {
            this.careerEvents = result;
        });
    }

    private loadProfile(profileId) {
        this.loading = true;
        this.adminTalentResource
            .getDetailedTalentForAdmin(profileId)
            .then(p => {
                this.talent = p;
                this.loading = false;
                this.titleService.setTitle(this.talent.firstName + ' ' + this.talent.lastName);
            });
    }

    deactivateTalent() {
        this.loadingDeactivateTalent = true
        let data: TalentDeactivationData = {
            reason: 'AdminDeletion',
            anonymize: true,
            withdrawRequests: true,
            applicationId: null,
        }

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                onConfirm: () => this.adminTalentResource.deactivateTalent(data, this.talentId),
                title: `Talent Löschen`,
                message: `Wenn du das Talent (${this.talent.firstName} ${this.talent.lastName}) löschst, werden alle Anfragen zurückgezogen und das Talent in 3 Wochen anonymisiert.`,
                confirmButtonText: "löschen",
                confirmButtonColor: "warn"
            },
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                this.talent.deleted = true
                this.loadingDeactivateTalent = false
            }
        })
    }

    save() {
        this.savingContent = true;
        this.adminResource.setTalentNote({value: this.adminNote}, this.talentId).then(() => {
        });
        this.adminTalentResource.editDetailedTalent(this.talent, this.talentId).then(
            () => {
                this.savingContent = false;
                this.utils.showSnackBar('Erfolgreich gespeichert');
            });
    }

    deleteCareerEvent(event) {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                onConfirm: () => this.adminCareerEventResource.removeCareerEvent({
                    talentId: this.talentId,
                    id: event.id
                }),
                title: `Ereignis löschen`,
                message: `Möchtest du das Werdegangsereignis \"${this.careerTypePipe.transform(event.type)}\" wirklich löschen?`,
                confirmButtonText: 'Löschen',
                confirmButtonColor: 'primary'
            },
            panelClass: 'custom-dialog-container'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                this.loadCareerEvents();
                this.utils.showSnackBar('Der Eintrag wurde gelöscht');
            }
        });
    }

    openCareerEventDetails(careerEvent) {
       this.dialog.open(CareerEventDetailsDialogComponent, {
            data: careerEvent,
            panelClass: 'custom-dialog-container'
        });
    }

    editProfilePicture() {
        let dialogRef = this.dialog.open(EditImageDialogComponent, {
            data: {
                title: 'Profilbild hochladen',
                aspectRatio: 1,
                roundCropping: true,
                onSave: null, //TODO some promise to save the pic
                imageData: null //TODO give data of loaded image , if you have one
            },
            panelClass: 'custom-dialog-container'
        });

        let uploaded: any;

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                uploaded = data;
                this.fileService.uploadTalentProfilePicture(this.talentId, data.file).subscribe(
                    () => {
                        this.loadProfilePicture();
                        this.timeStamp = (new Date()).getTime();
                    }
                );
            }
        });

        if (uploaded.target.files && uploaded.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(uploaded.target.files[0]);
            reader.onload = (e: any) => {
                this.profilePicture = e.target.result;

                this.fileService.uploadTalentProfilePicture(
                    this.talentId, this.createImageFile(this.profilePicture, uploaded.target.files[0].name)).subscribe(() => {
                    this.utilityService.showSnackBar('Profilbild aktualisiert');
                });
            };
        }
    }

    loadProfilePicture() {
        this.fileService.getTalentProfilePicture(this.talentId).subscribe((result) => {
            if (result.size > 0) {
                this.profilePicture = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
            }
        });
    }

    loadLogEntries() {
        this.loadingLogs = true
        let filterData = <AdminTalentLogEntryFilterData>{
            talentId: this.talentId,
            dateFrom: this.logDateFrom,
            dateTo: this.logDateTo,
            includeApplication: this.applicationCheckbox.value,
            includeCoaching: this.coachingCheckbox.value,
            includeOnAndOffboarding: this.onAndOffBoardingCheckbox.value,
            includeProfileChanges: this.profileChangeCheckbox.value,
            includeOthers: this.othersCheckbox.value}

        this.adminTalentResource.getTalentLogEntries(filterData).then(logs=>{
            this.logEntries = logs
            this.loadingLogs = false
        })
    }


    getPrimePositions() {
        this.loadingPrime = true
        let filterData = <PositionRelationFilterData>{}
        filterData.talentId = this.talentId
        filterData.isPrimeFilterMatch = this.onlySuitablePrimePositions ? this.onlySuitablePrimePositions : null
        filterData.origins = ['Uniwunder']

        this.adminEmploymentPositionResource.getPrimePositionsForTalent( filterData
        ).then(result => {
            this.primePositionsData = result
            this.talentPositionRelationDataSource.data = this.primePositionsData
            this.loadingPrime = false
        })
    }

    openPositionDetailsDialog(positionId) {
        let comp = this.sideSheetService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = positionId
    }

    markPrimePositionAsUnsuitable(position) {
        const comp = this.sideSheetService.openOverlay(MarkPrimePositionAsUnsuitableDialogComponent, 'Small')
        comp.instance.position = position
        comp.instance.talentId = this.talentId

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
            if (result) this.getPrimePositions()
            subscription.unsubscribe();
        });
    }

    sendNotReachedEmail() {
        this.sendingPrimePositionNotification = true
        this.adminPrimePositionRecommendationResource.sendNotReachedEmailForTalent(
            this.talentId,
            {positionIds: this.selection?.selected?.map(it => it.position.id)}
    ).then(() => {
            this.sendingPrimePositionNotification = false
        })
    }

    openCreatePositionRecommendationsDialog() {
        const comp = this.sideSheetService.openOverlay(CreatePositionRecommendationsDialogComponent, 'Small')
        comp.instance.positions = this.selection?.selected?.map(it => <LabelData>{name: it.position.title, id: it.position.id})
        comp.instance.mode = 'SingleTalentMode'
        comp.instance.talentId = this.talentId

        let subscription = comp.instance.sideSheetRef.sheetClosed.subscribe(result => {
            if (result) this.getPrimePositions()
            subscription.unsubscribe();
        });
    }


    createImageFile(file, fileName: string) {
        const imageBlob = this.dataURItoBlob(file.replace(RegExp('data.*base64,'), ''));
        return new File([imageBlob], fileName, {type: 'image/jpeg'});
    }

    dataURItoBlob(dataURI) {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        return new Blob([arrayBuffer], {type: 'image/jpeg'});
    }

    openMatch(positionId: number) {
        let cref = this.sideSheetService.openOverlay(MatchingAnalyzeSheetComponentComponent)
        cref.instance.talentId = this.talentId
        cref.instance.positionId = positionId
    }

    selection = new SelectionModel<AdminTalentPositionRelationData>(true, []);

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.primePositionsData.length;
        return numSelected === numRows;
    }

    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.primePositionsData.forEach(row => this.selection.select(row));
    }





    forceMatching() {
        this.currentlyMatching = true
        this.adminTalentResource.forceMatchingTalent(this.talent.id).then(() => {
            this.currentlyMatching = false
        })
    }

    applyFilter(dataSource: MatTableDataSource<any>, filterValue: string) {
        filterValue = filterValue.trim(); // Remove whitespace
        filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
        dataSource.filter = filterValue;
    }
}
