import {Component, Input, OnInit} from '@angular/core';
import {AdminApplicationData, AdminApplicationStateCountsData} from "../../generated/data";
import {FormBuilder} from "@angular/forms";
import {PageEvent} from "@angular/material/paginator";
import {AdminApplicationResource, AdminCompanyResource} from "../../generated/resources";

@Component({
  selector: 'company-applications',
  templateUrl: './company-applications.component.html',
  styleUrls: ['./company-applications.component.scss']
})
export class CompanyApplicationsComponent implements OnInit {

  @Input() companyId: number

  public pageSize: number = 15;
  public pageSizeOptions: number[] = [15, 30, 60, 100];
  public totalSize: number = 0;
  public pageIndex: number = 0;

  public displayedColumns = ['creationDate', 'talentName', 'talentEmail', 'positionTitle', 'state'];

  public applicationStateCounts: AdminApplicationStateCountsData;
  public applicationCount: number = 0;

  public dataSource: AdminApplicationData[] = [];
  public loading: Boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private adminCompanyResource: AdminCompanyResource,
    private adminApplicationResource: AdminApplicationResource
  ) {
  }

  ngOnInit(): void {
    this.getApplicationStateCounts();
    this.loadCompanyApplications();
  }

  handlePage(event: PageEvent) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.loadCompanyApplications();
  }

  loadCompanyApplications() {
    this.loading = true;

    this.adminCompanyResource.getApplicationsForCompany(this.companyId, {
      pageSize: this.pageSize,
      page: this.pageIndex
    }).then(result => {
      this.dataSource = result.content;
      this.totalSize = result.totalElements;
      this.loading = false;
    });
  }

  getApplicationStateCounts() {
    this.adminApplicationResource.countApplicationStates(this.companyId).then(result => {
      this.applicationStateCounts = result;

      for (let count of Object.keys(this.applicationStateCounts)) {
        this.applicationCount += this.applicationStateCounts[count]
      }
    })
  }
}
