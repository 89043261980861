import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AdminDetailedData, AdminRoleData, JsonWrappedValue} from "../../generated/data";
import {UtilsService} from "../../utils/utils.service";
import {UtilityService} from "../../utils/utility.service";
import {ActivatedRoute} from "@angular/router";
import {AdminAdminResource} from "../../generated/resources";
import {EmailAddressAvailabilityValidatorService} from "../../services/email-address-availability-validator.service";
import {MatDialog} from "@angular/material/dialog";
import {FileService} from "../../services/file.service";
import {DomSanitizer} from "@angular/platform-browser";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {EditImageDialogComponent} from "../../utils/edit-image-dialog/edit-image-dialog.component";

@Component({
    selector: 'app-account-edit-side-sheet',
    templateUrl: './account-edit-side-sheet.component.html',
    styleUrls: ['./account-edit-side-sheet.component.scss']
})
export class AccountEditSideSheetComponent implements OnInit {

    public adminId: number;
    public loading: boolean;
    public contactForm: FormGroup;
    public adminRoles: AdminRoleData[] = [];

    adminPicture: any

    public keyAccounterForm: FormGroup;
    public keyAccounterPicture: any;
    public noKeyAccounterRolePicture: Boolean = false;
    public hasRoleKeyAccounter: boolean = false;

    constructor(
        private fb: FormBuilder,
        private utilsService: UtilsService,
        private utilityService: UtilityService,
        private activatedRoute: ActivatedRoute,
        private adminAdminResource: AdminAdminResource,
        private emailAddressAvailabilityValidatorService: EmailAddressAvailabilityValidatorService,
        private dialog: MatDialog,
        private fileService: FileService,
        private sanitizer: DomSanitizer,
        private sideSheetRef: SideSheetRef
    ) {
        this.contactForm = fb.group({
            firstName: [null, Validators.required],
            lastName: [null],
            emailAddress: [null, [Validators.required, Validators.email]],
            phoneNumber: [null, [Validators.required, this.utilsService.validatePhoneNumber()]]
        })
    }

    ngOnInit() {
        this.adminAdminResource.getAdmin(this.adminId).then(admin => {
                this.contactForm.patchValue({
                    firstName: admin.firstName,
                    lastName: admin.lastName,
                    emailAddress: admin.emailAddress,
                    phoneNumber: admin.phoneNumber
                })
            }
        );

        this.loadAdminRoles();
        this.loadProfilePictures();

        if (!this.hasRoleKeyAccounter) this.buildKeyAccounterForm();

    }

    loadAdminRoles() {
        this.adminAdminResource.getAdminRoles(this.adminId).then(result => {
            result.forEach((adminRoleData) => {
                if (adminRoleData.role == 'KeyAccounter') {
                    this.hasRoleKeyAccounter = true;
                    this.buildKeyAccounterForm(adminRoleData.contactEmailAddress, adminRoleData.contactPhoneNumber, adminRoleData.demoDeskLink, adminRoleData.aboutMeText);
                }
            });
            this.adminRoles = result;
        });
    }

    loadProfilePictures() {
        this.fileService.getKeyAccounterProfilePicture(this.adminId).subscribe((result) => {
            if (result.size == 0) {
                this.noKeyAccounterRolePicture = true;
            } else {
                this.keyAccounterPicture = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
            }
        });

        this.fileService.getAdminProfilePicture(this.adminId).subscribe((result) => {
            if (result.size != 0) {
                this.adminPicture = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
            }
        });
    }

    buildKeyAccounterForm(email: string = null, phoneNumber: string = null, demoDeskLink:string = null, profileText: string = null) {
        this.keyAccounterForm = this.fb.group({
            contactEmailAddressControl: [email, [Validators.required, Validators.email]],
            contactPhoneNumberControl: [phoneNumber],
            demoDeskLink: [demoDeskLink],
            profileTextControl: [profileText, [Validators.required, Validators.minLength(20)]]
        })
    }

    updateAdminAccountInfo() {
        let data: AdminDetailedData = {
            emailAddress: this.contactForm.controls['emailAddress'].value,
            firstName: this.contactForm.controls['firstName'].value,
            lastName: this.contactForm.controls['lastName'].value,
            phoneNumber: this.contactForm.controls['phoneNumber'].value
        };

        this.loading = true;
        this.adminAdminResource.updateAdminAccountInfo(data, this.adminId).then(() => {
                this.loading = false;
                this.utilityService.showSnackBar("Admin geändert");
            }
        )
    }

    onFileChange(data, role: string) {
        if (data) {
            if (role == 'Admin') {
                this.fileService.uploadAdminProfilePicture(
                    this.adminId, data.file, role).subscribe(() => {
                    this.loadProfilePictures()
                    this.utilityService.showSnackBar('Profilbild aktualisiert');
                });
            } else {
                this.noKeyAccounterRolePicture = false;
                this.fileService.uploadAdminRoleProfilePicture(
                    this.adminId, data.file, role).subscribe(() => {
                    this.loadProfilePictures()
                    this.utilityService.showSnackBar('Profilbild aktualisiert');
                });
            }

        }
    }

    setRole(role: string) {
        this.adminAdminResource.setAdminRole(<JsonWrappedValue<string>>{value: role}, this.adminId).then(() => {
            this.hasRoleKeyAccounter = true;
            this.loadAdminRoles();
        });
    }

    updateAdminRole(role: string) {
        this.loading = true;
        this.adminAdminResource.updateAdminRole(
            {
                adminName: null,
                role: role,
                contactEmailAddress: this.keyAccounterForm.controls['contactEmailAddressControl'].value,
                contactPhoneNumber: this.keyAccounterForm.controls['contactPhoneNumberControl'].value,
                aboutMeText: this.keyAccounterForm.controls['profileTextControl'].value,
                demoDeskLink: this.keyAccounterForm.controls['demoDeskLink'].value
            },
            this.adminId
        ).then(() => {
            this.adminAdminResource.getAdminRoles(this.adminId).then(() => {
                this.loading = false;
                this.utilityService.showSnackBar(`${role} Profil erfolgreich aktualisiert`);
            });
        });
    }

    deleteAdminRole(role: string) {
        this.adminAdminResource.deleteAdminRole(<JsonWrappedValue<string>>{value: role}, this.adminId).then((success) => {
            if (success.value) {
                this.hasRoleKeyAccounter = false;
            } else {
                this.utilityService.showSnackBar(`Rolle kann nicht entfernt werden, da sie derzeit die einzige aktive ${role} Rolle ist!`);
            }
        });
    }

    editProfilePicture(role: string) {
        let dialogRef = this.dialog.open(EditImageDialogComponent, {
            data: {
                title: `${role} Profilbild hochladen`,
                aspectRatio: 1,
                roundCropping: true,
                onSave: null, //TODO some promise to save the pic
                imageData: null //TODO give data of loaded image , if you have one
            },
            panelClass: 'custom-dialog-container'
        });

        let uploaded: any;

        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                this.onFileChange(data, role)
            }
        });
    }
}

