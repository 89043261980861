import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FileService} from '../../services/file.service';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {environment} from '../../../environments/environment';
import {
  AddressData,
  AdminCompanyData,
  AdminCompanyUpdateData,
  AdminRoleDisplayData, CompanyInvoiceInformationData,
  CompanyRole
} from '../../generated/data';
import {UtilityService} from '../../utils/utility.service';
import {
  AccountResource,
  AdminAdminResource,
  AdminApplicationResource,
  AdminCompanyResource,
  AdminEmploymentPositionResource,
  AdminResource,
  LocationResource,
} from '../../generated/resources';
import {EditImageDialogComponent} from '../../utils/edit-image-dialog/edit-image-dialog.component';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {CompanyEmploymentPositionsComponent} from "../company-employment-positions/company-employment-positions.component";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-company-site',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss']
})
export class CompanyDetailsComponent implements OnInit, OnDestroy {

  @ViewChild("#positionsCmp") positionsCmp: CompanyEmploymentPositionsComponent

  public companyId: number;
  public isCustomer: boolean = false;
  public adminCompanyData: AdminCompanyData;

  public companyForm: FormGroup;
  public landingPageForm: FormGroup;

  public keyAccounterControl = new FormControl(null);
  public keyAccounterData: AdminRoleDisplayData[] = [];

  public customCitySelected: boolean = false;
  public loading: boolean = true;
  public updateApiPositionSearchabilityLoading: boolean = false;

  public apiUrl;

  companyRoles: CompanyRole[] = ["EventManagement","ApplicationManagement"]

  companyFormSub: Subscription

  constructor(
    private route: ActivatedRoute,
    private adminCompanyResource: AdminCompanyResource,
    private adminAdminResource: AdminAdminResource,
    private utilityService: UtilityService,
    private adminApplicationResource: AdminApplicationResource,
    private locationResource: LocationResource,
    private fileService: FileService,
    private http: HttpClient,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private router: Router,
    private accountResource: AccountResource,
    private titleService: Title,
    private adminResource: AdminResource,
    private adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private sanitizer: DomSanitizer,
    private fb: FormBuilder
  ) {
    this.companyForm = fb.group({
      nameControl: null,
      streetNameControl: null,
      streetNumberControl: null,
      zipCodeControl: null,
      cityIdControl: null,
      cityNameControl: null,
      consideredForReverseRecruiting: null,
      reverseRecruitingCount: null,
      websiteControl: null,
      applicationGrantStrategy: null,
      shortCompanyInfoControl: null,
      googleJobPosting: null,
      primeCompany: null,
      showProfile: null,
      companyRoles: null,
      invoiceEmailControl: null,
      invoiceHolderFirstNameControl: null,
      invoiceHolderLastNameControl: null,
      taxNumberControl: null,
      purchaseInfoControl: null
    })

    this.landingPageForm = fb.group({
      showOnLandingPageControl: false,
      shortLandingPageTextControl: false,
      landingPageAboutTextControl: false
    })
  }

  ngOnDestroy(): void {
    this.companyFormSub?.unsubscribe()
    }

  ngOnInit() {
    this.apiUrl = environment.apiUrlMain;
    this.companyId = parseInt(this.route.snapshot.paramMap.get('id'));

    this.companyForm.controls.consideredForReverseRecruiting.valueChanges.subscribe(next => {
      if (next) this.companyForm.controls.reverseRecruitingCount.enable()
      else this.companyForm.controls.reverseRecruitingCount.disable()
    })

    this.loadData();
  }

  loadData() {
    this.loadCompanyAddressData();
    this.loadCompanyKeyAccounterData();
    this.loadCompanyData();
    this.loadKeyAccounter();
    this.loadProfilePictures();
  }

  loadCompanyData() {
    this.adminCompanyResource.getCompany(this.companyId).then(company => {
      this.adminCompanyData = company;
      this.titleService.setTitle(this.adminCompanyData.name);
      this.companyForm.controls.nameControl.setValue(this.adminCompanyData.name)
      this.companyForm.controls.googleJobPosting.setValue(this.adminCompanyData.googleJobPosting)
      this.companyForm.controls.websiteControl.setValue(this.adminCompanyData.website)
      this.companyForm.controls.shortCompanyInfoControl.setValue(this.adminCompanyData.shortCompanyInfo)
      this.companyForm.controls.reverseRecruitingCount.setValue(this.adminCompanyData.reverseRecruitingCount)
      this.companyForm.controls.consideredForReverseRecruiting.setValue(this.adminCompanyData.consideredForReverseRecruiting)
      this.companyForm.controls.applicationGrantStrategy.setValue(this.adminCompanyData.applicationGrantStrategy)
      this.companyForm.controls.primeCompany.setValue(this.adminCompanyData.primeCompany)
      this.companyForm.controls.showProfile.setValue(this.adminCompanyData.showProfile)
      this.companyForm.controls.companyRoles.setValue(this.adminCompanyData.companyRoles)
      this.companyForm.controls.invoiceEmailControl.setValue(this.adminCompanyData.companyInvoiceInformation.invoiceEmail)
      this.companyForm.controls.invoiceHolderFirstNameControl.setValue(this.adminCompanyData.companyInvoiceInformation.invoiceHolderFirstName)
      this.companyForm.controls.invoiceHolderLastNameControl.setValue(this.adminCompanyData.companyInvoiceInformation.invoiceHolderLastName)
      this.companyForm.controls.taxNumberControl.setValue(this.adminCompanyData.companyInvoiceInformation.taxNumber)
      this.companyForm.controls.purchaseInfoControl.setValue(this.adminCompanyData.companyInvoiceInformation.additionalPurchaseInformation)

      if (this.landingPageForm.controls.landingPageAboutTextControl.value == null) {
        this.landingPageForm.controls.landingPageAboutTextControl.setValue(this.getExampleHTML());
      }

      this.loading = false;
    });
  }

  loadCompanyAddressData() {
    this.adminCompanyResource.getCompanyAddress(this.companyId).then(address => {
      this.companyForm.controls.streetNameControl.setValue(address.streetName)
      this.companyForm.controls.streetNumberControl.setValue(address.streetNumber)
      this.companyForm.controls.zipCodeControl.setValue(address.zipCode)
      this.companyForm.controls.cityIdControl.setValue(address.cityId)
      this.companyForm.controls.cityNameControl.setValue(address.cityName)
    })
  }

  loadCompanyKeyAccounterData() {
    this.adminCompanyResource.getCompanyKeyAccount(this.companyId).then(keyAccounter => {
      this.keyAccounterControl.setValue(keyAccounter.adminRoleId)
    })
  }

  loadKeyAccounter() {
    this.adminAdminResource.getAllKeyAccounter().then(keyAccounterData => {
      this.keyAccounterData = keyAccounterData;
    });
  }

  showCustomCityInput(show: boolean) {
    this.customCitySelected = show;
    this.companyForm.controls.cityControl.setValue(null)
  }

  saveCustomCities() {
    if (this.companyForm.controls.cityNameControl.value == null || this.companyForm.controls.cityNameControl.value == '') return

    this.companyForm.controls.cityNameControl.setValue(this.companyForm.controls.cityNameControl.value.split(","))
   /* this.locationResource.createCities(this.companyForm.controls.cityNameControl.value).then(() => {
      this.showCustomCityInput(false);
    })*/
  }

  updateCompany() {
    this.loading = true;

    let companyData: AdminCompanyUpdateData = {
      id: this.adminCompanyData.id,
      name: this.companyForm.controls.nameControl.value,
      primeCompany: this.companyForm.controls.primeCompany.value,
      showProfile: this.companyForm.controls.showProfile.value,
      companyInvoiceInformationData: <CompanyInvoiceInformationData>{
        companyId: this.adminCompanyData.id,
        invoiceEmail: this.companyForm.controls.invoiceEmailControl.value,
        invoiceHolderFirstName: this.companyForm.controls.invoiceHolderFirstNameControl.value,
        invoiceHolderLastName: this.companyForm.controls.invoiceHolderLastNameControl.value,
        taxNumber: this.companyForm.controls.taxNumberControl.value,
        additionalPurchaseInformation: this.companyForm.controls.purchaseInfoControl.value
      },
      address: <AddressData>{
        name: null,
        streetName: this.companyForm.controls.streetNameControl.value,
        streetNumber: this.companyForm.controls.streetNumberControl.value,
        zipCode: this.companyForm.controls.zipCodeControl.value,
        cityId: this.companyForm.controls.cityIdControl.value,
        cityName: this.companyForm.controls.cityNameControl.value,
      },
      googleJobPosting: this.companyForm.controls.googleJobPosting.value,
      companyRoles: this.companyForm.controls.companyRoles.value,
      website: this.companyForm.controls.websiteControl.value,
      keyAccounterId: this.keyAccounterControl.value,
      reverseRecruitingCount: this.companyForm.controls.reverseRecruitingCount.value,
      consideredForReverseRecruiting: this.companyForm.controls.consideredForReverseRecruiting.value,
      applicationGrantStrategy: this.companyForm.controls.applicationGrantStrategy.value,
      shortCompanyInfo: this.companyForm.controls.shortCompanyInfoControl.value,
      yearlyRecruitings: this.adminCompanyData.yearlyRecruitings
    }

    this.adminCompanyResource.updateCompanyDetails(companyData).then(() => {
      this.loading = false;
      this.utilityService.showSnackBar("Firma erfolgreich bearbeitet!");
      this.loading = false;
    })
  }

  data: {
    title: 'Logo auswählen',
    aspectRatio: 1,
    roundCropping: true,
    onSave: null, //TODO some promise to save the pic
    imageData: null //TODO give data of loaded image , if you have one
  };

  loadProfilePictures() {
    this.fileService.getCompanyLogo(this.companyId).subscribe((result) => {
      if (result.size > 0) {
        this.companyLogo = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
      }
    });

    this.fileService.getCompanyBanner(this.companyId).subscribe((result) => {
      if (result.size > 0) {
        this.companyBanner = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(result));
      }
    });
  }

  companyLogo: any;

  onEditImage() {
    let dialogRef = this.dialog.open(EditImageDialogComponent, {
      data: {
        title: 'Logo hochladen',
        aspectRatio: 1,
        roundCropping: true,
      },
      panelClass: 'custom-dialog-container'
    });

    let uploaded: any;

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        uploaded = data;
        this.fileService.uploadCompanyLogo(this.companyId, data.file).subscribe(
          () => {
            this.loadProfilePictures();
          }
        );
      }
    });

    if (uploaded.target.files && uploaded.target.files[0]) {
      const reader = new FileReader();
      reader.readAsDataURL(uploaded.target.files[0]);
      reader.onload = (e: any) => {
        this.companyLogo = e.target.result;

        this.fileService.uploadCompanyLogo(
          this.companyId, this.createImageFile(this.companyLogo, uploaded.target.files[0].name)).subscribe(() => {
          this.utilityService.showSnackBar('Logo aktualisiert');
        });
      };
    }
  }

  companyBanner: any;

  createImageFile(file, fileName: string) {
    const imageBlob = this.dataURItoBlob(file.replace(RegExp('data.*base64,'), ''));
    return new File([imageBlob], fileName, {type: 'image/jpeg'});
  }

  dataURItoBlob(dataURI) {
    const byteString = atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], {type: 'image/jpeg'});
  }


  getExampleHTML(): string {
    return `<h1 style="font-weight: 100; margin-bottom: 5px;">Über FIRMA</h1>
             <span style="color: gray; font-size: small; font-style: italic;">von AUTOR, DATUM</span>
              <p style="padding: 0 0 25px 0; margin: 20px 0 5px 0; border-bottom: 1px solid #7373732e;">
              OBERER TEXT
              </p>
              ` + this.getQuestionAnswerTemplate();
  }

  getQuestionAnswerTemplate(): string {
    return `<div style="display: inline-block; width: 100%; margin-top: 10px">
                <div style="width: 110px; margin: 0; float: left;">
                  <div style="width: 100%;">
                    <div
                         style="
                         background-image: url(FOTO URL VON UNSEREM INTERVIEWER);
                    width: 60px;
                    height: 60px;
                    margin: 20px auto;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    border: 1px solid #e0e0e0;
                    border-radius: 100%;
                    background-color: white;">
                      <div style="
                        margin-top: 65px;
                        font-size: small;
                        width: 100px;
                        margin-left: -20px;
                        text-align: center;
                        color: #737373;"
                        class="picture-label"
                        >
                        UNSER INTERVIEWER
                      </div>
                    </div>
                  </div>
                </div>
                <div style="width: 85%; margin-left: auto; background-color: #d9f2ff; border-radius: 7px;" class="question">
                <div style="
                  margin-top: 20px;
                  font-size: large;
                  padding:7px;
                  color: #737373;">
                  <div style="
                    font-size: 2rem;
                    float: left;
                    margin-top: -5px;
                    padding-right: 3px;">
                    “
                  </div>
                  <div>FRAGE &#8222;
                  </div>
                </div>
                </div>


                <div style="display: inline-block; width: 100%; margin-top:30px">
                  <div style="
                  float: left;
                  width: 85%;
                  background-color: #d4d4d447;
                  padding: 13px 5px 5px 5px;
                  border-radius: 7px;">
                    <div style="
                    font-size: 2rem;
                    float: left;
                    margin-top: -5px;
                    height: 10px;
                    padding-right: 5px;">
                      “
                    </div>
                    <div style="margin-top: -20px">
                      <p>TEXT</p>

                      <p>HIER können so viele p>\'s rein, wie du magst :) aber am ende muss das bleiben ---> &#8222;</p>
                    </div>
                  </div>
                  <div style="width: 110px; float: right;">
                    <div style="width: 100%;">
                      <div
                        style="
                           background-image: url(FOTO URL DES INTERVIEWPARTNERS);
                    width: 60px;
                    height: 60px;
                    margin: 0 auto 20px auto;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    border: 1px solid #e0e0e0;
                    border-radius: 100%;
                    background-color: white;">
                        <div style="
                        margin-top: 65px;
                        font-size: small;
                        width: 100px;
                        margin-left: -20px;
                        text-align: center;
                        color: #737373;">
                          NAME DES INTERVIEWPARTNERS
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>`;
  }

  updateApiPositionSearchability() {
    this.updateApiPositionSearchabilityLoading = true
    this.adminCompanyResource.updateApiPositionSearchabilityForCompany(this.companyId).then(() => {
      this.adminCompanyData.searchEnabledForApiPositions = !this.adminCompanyData.searchEnabledForApiPositions
      this.updateApiPositionSearchabilityLoading = false
    })
  }
}
