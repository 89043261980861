import { Component, OnInit } from '@angular/core';
import {
  AdminPositionMatchingConfigData,
  LabelData, PositionProfessionFieldRelationData,
  ProfessionData,
  ProfessionSelectionData
} from "../../generated/data";
import {AdminMatchingResource, AdminProfessionResource} from "../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";
import {FormControl} from "@angular/forms";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {MappingExplorerEntrySheetComponent} from "../mapping-explorer-entry-sheet/mapping-explorer-entry-sheet.component";
import {ProfessionDetailsSheetComponent} from "../mapping-explorer/profession-details-sheet/profession-details-sheet.component";
import {PageEvent} from "@angular/material/paginator";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";

@Component({
  selector: 'app-profession-selection-sheet',
  templateUrl: './profession-selection-sheet.component.html',
  styleUrls: ['./profession-selection-sheet.component.scss']
})
export class ProfessionSelectionSheetComponent implements OnInit {

  //Inputs
  position: LabelData //nullable
  config: AdminPositionMatchingConfigData; //nullable


  suggestions: ProfessionData[] = [];
  professions: ProfessionSelectionData[] = [];
  filterControl: FormControl = new FormControl('');



  constructor(
      private professionResource: AdminProfessionResource,
      private maxNenntAllesFalschService: SideSheetService,
      private sideSheetRef: SideSheetRef,
      private adminMatchingResource:AdminMatchingResource
  ) {
    this.filterControl.valueChanges.subscribe(res => {
      this.loadProfessions()
    })
    this.loadProfessions();
  }

  ngOnInit(): void {
    this.filterControl.valueChanges
        .pipe(debounceTime(2000))
        .subscribe(newValue => {
          this.loadProfessions()
        });

    this.loadProfessionSuggestions();
  }

  pageIndex = 0;
  pageSize=30;
  totalElements;
  loading = false;
  loadProfessions() {
    this.loading = true;
    this.professionResource.getProfessionsSelectionData({
      query: this.filterControl.value,
      page: this.pageIndex,
      pageSize: this.pageSize,
    }).then(res => {
      this.totalElements = res.totalElements;
          this.professions = res.content;
      this.loading = false;
        }

    )
  }


  selectProfession(profession: ProfessionData){
    this.config.profession = <LabelData>{id: profession.id, name: profession.title};
  }



  loadingFields = false;
  loadFields() {
    this.loadingFields = true;
    //This sheet can be opened in position import before the position has actually been created. In that case you just need to load the fields
    if(this.position?.id) {
      this.adminMatchingResource.getProfessionFieldRelations(this.position.id).then(res => {
        this.config.positionProfessionFieldRelations = res;
        this.loadingFields = false;
      })
    }else{
      this.professionResource.getRelevantFieldsForProfession(this.config.profession.id).then(fields => {
        this.config.positionProfessionFieldRelations = fields.map(field => <PositionProfessionFieldRelationData>{field: field, positionId: null, qualification: 'Normal'});
        this.loadingFields = false;
      })
    }
  }


  selectAndClose(){
    this.sideSheetRef.sheetClosed.next(this.config)
  }

  handlePage(event: PageEvent): void {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      this.loadProfessions();
    }

  suggestionsLoading: boolean = false;
  loadProfessionSuggestions() {
    if(!this.position)return
    this.suggestionsLoading = true

    this.professionResource.getBestMatchingProfessionForPositionTitle({title: this.position.name})
        .then(professions => {
          this.suggestions = professions
          this.suggestionsLoading = false
        })
  }

  openExplorer(){
    this.maxNenntAllesFalschService.openOverlay(MappingExplorerEntrySheetComponent, 'Medium');
  }

  openProfessionDetails(profession: ProfessionSelectionData){
    let comp = this.maxNenntAllesFalschService.openOverlay(ProfessionDetailsSheetComponent, 'Medium')
    comp.instance.professionId = profession.profession.id;
    comp.instance.professionTitle = profession.profession.title
  }
}
