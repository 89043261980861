import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {saveAs} from 'file-saver/FileSaver';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private apiUrl: string;

  constructor(private http: HttpClient) {
  }

  downloadTalentEventRegistrationsExcel(eventId: number) {
    this.http.get(`${environment.apiUrlMain}/admin/event/${eventId}/registrations/excel`, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {

      let blob = new Blob([response.body]);

      if (blob.size > 0) {
        saveAs(blob, this.getFileNameFromHeaders(response.headers));
      }
    });
  }

    downLoadWebinarRegistrations(start: Date, end: Date): Promise<any> {
      return new Promise(resolve => {
          this.http.get(`${environment.apiUrlMain}/admin/webniar/excelDownload`, {
              headers: new HttpHeaders(),
              responseType: 'blob',
              observe: 'response',
              params: {start: start.toISOString(), end: end.toISOString()}
          }).subscribe((response: HttpResponse<Blob>) => {

              let blob = new Blob([response.body]);

              if (blob.size > 0) {
                  saveAs(blob, this.getFileNameFromHeaders(response.headers));
              }

              resolve(response)
          });
      })
    }

  downloadTalentEventFilterExcel(eventId: number) {
    this.http.get(`${environment.apiUrlMain}/admin/event/${eventId}/filter/talents/excel`, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {

      let blob = new Blob([response.body]);

      if (blob.size > 0) {
        saveAs(blob, this.getFileNameFromHeaders(response.headers));
      }
    });
  }

  getFileNameFromHeaders(headers: HttpHeaders): string {
      let contentDisposition = headers.get("Content-Disposition");
      if (!contentDisposition) return "cv.pdf";
      return headers.get("Content-Disposition").split(";")[1].trim().split("=")[1];
  }

  getMatchesExcel(positionId: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + '/positions/' + positionId + '/prematchedtalents/excel', {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  uploadCompanyLogo(id: number, imageFile: File): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);
    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + '/admin/company/' + id + '/logo', imageFile, options);
  }


  getTalentProfilePicture(id: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/talents/${id}/profilePic`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  uploadTalentProfilePicture(id: number, imageFile: File): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + '/talent/' + id + '/profilePicture', imageFile, options);
  }

  getCompanyLogo(id: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/companies/${id}/logo`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  uploadCompanyBanner(id: number, imageFile: File): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + '/admin/company/' + id + '/banner', imageFile, options);
  }

  getCompanyBanner(id: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/companies/${id}/banner`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  uploadConsultantProfilePicture(consultantId, imageFile: File): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + '/consultants/' + consultantId + '/profilePicture', imageFile, options);
  }

  getConsultantProfilePicture(consultantId: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/consultants/${consultantId}/profilePicture`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  uploadAdminRoleProfilePicture(adminId, imageFile: File, role: string): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + '/admins/' + adminId + '/' + role + '/profilePicture', imageFile, options);
  }

  getTalentAgentProfilePicture(adminId: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/admins/${adminId}/talentAgent/profilePicture`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  getKeyAccounterProfilePicture(adminId: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/admins/${adminId}/keyAccounter/profilePicture`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    })
  }

  getAdminProfilePicture(adminId: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/admins/${adminId}/profilePicture`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    })
  }

  uploadAdminProfilePicture(adminId, imageFile: File, role: string): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.name);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + '/admins/' + adminId + '/profilePicture', imageFile, options);
  }

  uploadPositionExcelFile(file): Observable<Blob> {
    let formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    return this.http.post(environment.apiUrlMain + '/positions/uploadExcel', formData, {
      responseType: 'blob'
    })
  }

  uploadPositionContentSplitPicture(splitId, file): Observable<Object> {
    let params = new HttpParams()
        .append("fileName", file.name)

    let options = {
      header: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params
    }

    return this.http.post(environment.apiUrlMain + `/admin/contentSplits/${splitId}/picture`, file, options)

  }

  uploadEventImage(eventId, file): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    return this.http.post(environment.apiUrlMain + `/admin/events/${eventId}/addImage`, formData, {
      responseType: 'json'
    })
  }

  uploadEventPartnerLogo(eventId, file): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    return this.http.post(environment.apiUrlMain + `/admin/events/${eventId}/addPartnerLogo`, formData, {
      responseType: 'json'
    })
  }

  uploadEventHeader(eventId, file): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    return this.http.post(environment.apiUrlMain + `/admin/events/${eventId}/headerImage`, formData, {
      responseType: 'json'
    })
  }

  uploadRatingsExcelFile(file): Observable<Blob> {
    let formData: FormData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    return this.http.post(environment.apiUrlMain + '/ratings/uploadExcel', formData, {
      responseType: 'blob'
    })
  }

  downloadRecruitingPrivacyDisclaimerForPosition(positionId: number) {
    let subject = new Subject<Blob>();

    this.http.get(environment.apiUrlMain + '/positions/' + positionId + '/privacy/disclaimer/pdf', {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {

      const filename = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];

      if (response.body.size > 0) {
        saveAs(response.body, filename);
      }
    });
  }

  getCV(talentId: number): Observable<Blob> {

    let subject = new Subject<Blob>();

    this.http.get(environment.apiUrlMain + '/talentProfile/CurriculumVitae/' + talentId, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      subject.next(response.body)
    });

    return subject.asObservable()
  }

  downloadCv(talentId: number): Observable<any> {

    const subject = new Subject();

    this.http.get(environment.apiUrlMain + '/talentProfile/CurriculumVitae/' + talentId, {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {

      const filename = response.headers.get('Content-Disposition').split(';')[1].trim().split('=')[1];

      if (response.body.size > 0) {
        saveAs(response.body, filename);
      }

      subject.next();
    });

    return subject.asObservable();
  }

  downloadTalentExcel(): Observable<any> {
    return this.http.get(environment.apiUrlMain + "/talents/excel", {
      headers: new HttpHeaders(),
      responseType: 'blob'
    });
  }

  downloadDossier(talentId: number, body: any): Observable<any> {

    let subject = new Subject();

    this.http.post(environment.apiUrlMain + '/admin/talent/' + talentId + '/dossier', body, {
      headers: new HttpHeaders(),
      responseType: 'arraybuffer',
      observe: 'response'
    }).subscribe((response: HttpResponse<ArrayBuffer>) => {

      let blob = new Blob([response.body]);

      if (blob.size > 0) {
        saveAs(blob, this.getFileNameFromHeaders(response.headers));
      }

      subject.next();
    });

    return subject.asObservable()
  }

  getApplicationDossier(applicationId: number): Observable<Blob> {
    let subject = new Subject<Blob>();

    this.http.get(`${environment.apiUrlMain}/admin/applications/${applicationId}/dossier` , {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      subject.next(response.body)
    });

    return subject.asObservable()
  }

  getDossier(talentId: number): Observable<Blob> {
    let subject = new Subject<Blob>();

    this.http.get(`${environment.apiUrlMain}/admin/talents/${talentId}/dossier` , {
      headers: new HttpHeaders(),
      responseType: 'blob',
      observe: 'response'
    }).subscribe((response: HttpResponse<Blob>) => {
      subject.next(response.body)
    });

    return subject.asObservable()
  }

  getWebinarImage(webinarId: number): Observable<any> {
    return this.http.get(environment.apiUrlMain + `/admin/webinar/${webinarId}/image`, {
      headers: new HttpHeaders(),
      responseType: 'blob'
    })
  }

  uploadWebinarImage(webinarId: number, imageFile: any): Observable<Object> {
    const params = new HttpParams().set('fileName', imageFile.fileName);

    let options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream'
      }),
      params: params
    };

    return this.http.post(environment.apiUrlMain + `/admin/webinar/${webinarId}/image`, imageFile.file, options)
  }


}
