import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'requestState'
})
export class RequestStatePipe implements PipeTransform {

  transform(value: any): any {
    switch (value) {
      case 'ApplicationSentLogEntry':
      case 'ExternalApplicationCreationLogEntry':
        return 'Angelegt';
      case 'ApplicationGrantedLogEntry':
        return 'Admin hat bestätigt';
      case 'ApplicationInterestingLogEntry':
        return 'Unternehmen interessiert';
      case 'ApplicationProcessEnterLogEntry':
        return 'In Bewerbung';
      case 'ApplicationHiredLogEntry':
        return 'Eingestellt';
      case 'ApplicationWithdrawnLogEntry':
        return 'Talent hat zurückgezogen';
      case 'ApplicationFailedLogEntry':
        return 'Unternehmen hat aussortiert';
      case 'ApplicationContractSentLogEntry':
        return 'Unternehmen hat Arbeitsvertrag zugeschickt'
      case 'Pending':
        return 'Zur Kontrolle';
      case 'Draft':
        return 'Bewerbungsentwurf';
      case 'Granted':
        return 'Admin hat bestätigt';
      case 'Interesting':
        return 'Unternehmen interessiert';
      case 'AppProcessEnter':
        return 'In Bewerbung';
      case 'ContractSent':
        return 'Vertrag zugeschickt';
      case 'Hired':
        return 'Eingestellt';
      case 'Withdrawn':
        return 'Talent hat zurückgezogen';
      case 'Denied':
        return 'Admin hat aussortiert';
      case 'Failed':
        return 'Unternehmen hat aussortiert';
    }
  }
}
