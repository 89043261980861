<div style="background-color: white; min-width: 1030px; margin: 20px; padding: 10px" class="mat-elevation-z6">
  <div class="standard-container" fxLayout="column">

    <div class="button-bar" fxLayout="row" fxLayoutAlign="space-between start">

      <div style="width: 100%; margin-right: 15px;" fxLayout="row wrap" fxLayoutAlign="start end" fxLayoutGap="16px">

        <mat-form-field class="name-filter filter-field">
          <input matInput [formControl]="searchQuery" placeholder="Schnellsuche">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select [(ngModel)]="filter.states" [multiple]="true">

            <mat-option *ngFor="let requestState of requestStates" [value]="requestState">
              {{requestState | applicationState}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="pickerFrom" placeholder="Bewerbungen von"
                 [(ngModel)]="filter.dateFrom" name="dateFrom">
          <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <input matInput [matDatepicker]="pickerTo" placeholder="Bewerbungen bis" [(ngModel)]="filter.dateTo" name="dateTo">
          <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
          <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>

      </div>

      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
        <loading-button matTooltip="gefilterte Stellen laden" buttonClass="mat-primary" [loadingBool]="loading"
                        (clickEvent)="loadData()">
          <i class="mdi mdi-refresh main-action-icon filter-color"></i>
        </loading-button>
        <loading-button matTooltip="gefilterte Stellen laden" buttonClass="mat-primary" [loadingBool]="downloadExcel"
                        (clickEvent)="downloadApplicationExcel()">
          Bewerbungen als Excel herunterladen
        </loading-button>
      </div>
    </div>
    </div>

    <loading-spinner [loadingBool]="loading">
      <table mat-table [dataSource]="tableData" #dataTable>

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>Datum</th>
          <td mat-cell *matCellDef="let row"> {{row.creationDate | date: 'dd.MM.yy'}}</td>
        </ng-container>

        <ng-container matColumnDef="talentName">
          <th mat-header-cell *matHeaderCellDef>Talent</th>
          <td mat-cell *matCellDef="let row" (click)="copyToClipboard(row.talentName)"
              class="copy">{{row.talentName}}</td>
        </ng-container>

        <ng-container matColumnDef="talentSubjectName">
          <th mat-header-cell *matHeaderCellDef>Studiengang</th>
          <td mat-cell *matCellDef="let row" (click)="copyToClipboard(row.talentSubjectName)"
              class="copy">{{row.talentSubjectName}}</td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Unternehmen</th>
          <td mat-cell *matCellDef="let row" (click)="copyToClipboard(row.company)"
              class="copy">{{row.company | sliceStringLength: 30}}</td>
        </ng-container>

        <ng-container matColumnDef="positionTitle">
          <th mat-header-cell *matHeaderCellDef>Stelle</th>
          <td style="max-width: 300px" mat-cell *matCellDef="let row" (click)="copyToClipboard(row.position.internTitle)"
              class="copy">{{row.position.internTitle}}</td>
        </ng-container>

        <ng-container matColumnDef="origin">
          <th mat-header-cell *matHeaderCellDef>Herkunft</th>
          <td mat-cell *matCellDef="let row"
              class="copy">{{row.position.origin | employmentPositionOrigin}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row" class="request-state">
            <application-state [application]="row"></application-state>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="text-align: center !important;">Aktionen</th>
          <td mat-cell *matCellDef="let row">
            <div fxLayoutAlign="center center">
              <button mat-icon-button color="primary" (click)="openApplicationOverview(row)"
                      matTooltip="Bewerbungsübersicht öffnen">
                <i class="mdi mdi-account dark"></i>
              </button>

              <button mat-icon-button color="primary" (click)="openApplicationDetails(row)"
                      matTooltip="Bewerbungsdetails öffnen">
                <i class="mdi mdi-file dark"></i>
              </button>

              <button *ngIf="row.state == 'Sent'" [disabled]="actualAId == row.applicationId" mat-icon-button (click)="acceptApplication(row)"
                      matTooltip="Freigeben">
                <i class="mdi mdi-check" style="color: #228B22FF"></i>
              </button>

              <button *ngIf="row.state == 'Sent'" [disabled]="actualAId == row.applicationId" mat-icon-button color="warn" (click)="denyApplication(row)"
                      matTooltip="Ablehnen">
                <i class="mdi mdi-close red"></i>
              </button>


            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
  </div>

