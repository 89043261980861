import {Component, OnDestroy, OnInit} from '@angular/core';
import {AdminHiringStatisticData} from "../../generated/data";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {AdminApplicationResource} from "../../generated/resources";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Subscription} from "rxjs";
import {SideSheetService} from "../../utils/side-sheet/side-sheet.service";
import {
    PositionDetailsDialogComponent
} from "../../positions/position-details-dialog/position-details-dialog.component";
import {TalentProfileDialogComponent} from "../../talents/talent-profile-dialog/talent-profile-dialog.component";

@Component({
    selector: 'app-billings-hiring-details-dialog',
    templateUrl: './billings-hiring-details-dialog.component.html',
    styleUrls: ['./billings-hiring-details-dialog.component.scss']
})
export class BillingsHiringDetailsDialogComponent implements OnInit, OnDestroy {

    formGroup: FormGroup
    formSub: Subscription

    hiringData: AdminHiringStatisticData
    updateHiringLoading: boolean = false

    constructor(
        private fb: FormBuilder,
        private sideSheetRef: SideSheetRef,
        private sideSheetService: SideSheetService,
        private adminApplicationResource: AdminApplicationResource,
        private snackbar: MatSnackBar
    ) {
    }

    ngOnDestroy(): void {
        this.formSub?.unsubscribe()
    }

    ngOnInit(): void {
        this.initForm()
        // this.initFormSub()
    }

    initForm(): void {
        this.formGroup = this.fb.group({
            signingDate: [this.hiringData.signingDate],
            startingDate: [this.hiringData.startingDate],
            salaryState: [this.hiringData.salaryState ? this.hiringData.salaryState : "SalaryGiven", Validators.required],
            salaryText: new FormControl({value: this.hiringData.salaryAmount, disabled: true}, [Validators.required]),
            notes: [this.hiringData.adminNote]
        })

        // if (this.formGroup.controls.salaryState.value != "SalaryGiven") {
        //     this.formGroup.controls.salaryText.setValidators(null)
        //     this.formGroup.controls.salaryText.setErrors(null)
        //     this.formGroup.controls.salaryText.disable()
        // }
    }

    // initFormSub(): void {
    //     this.formSub = this.formGroup.controls.salaryState.valueChanges.subscribe(newValue => {
    //         if (newValue == "SalaryGiven") {
    //             this.formGroup.controls.salaryText.setValidators(Validators.required)
    //             this.formGroup.controls.salaryText.enable()
    //         } else {
    //             this.formGroup.controls.salaryText.setValidators(null)
    //             this.formGroup.controls.salaryText.setErrors(null)
    //             this.formGroup.controls.salaryText.disable()
    //         }
    //
    //         this.formGroup.updateValueAndValidity()
    //     })
    // }

    openPositionDetailsDialog() {
        let comp = this.sideSheetService.openOverlay(PositionDetailsDialogComponent, 'Huge')
        comp.instance.positionId = this.hiringData.positionId
    }

    openTalentProfile() {
        let comp = this.sideSheetService.openOverlay(TalentProfileDialogComponent, 'Huge')
        comp.instance.talentId = this.hiringData.talentId
    }

    updateHiring(): Promise<any> {
        this.updateHiringLoading = true
        return this.adminApplicationResource.updateHiringInformationForApplication(
            {
                salaryAmount: this.formGroup.controls.salaryText.value,
                startingDate: this.formGroup.controls.startingDate.value,
                signingDate: this.formGroup.controls.signingDate.value,
                adminNote: this.formGroup.controls.notes.value,
                salaryState: this.formGroup.controls.salaryState.value
            },
            this.hiringData.applicationId
        ).then(result => {
            if (result) {

                this.hiringData.signingDate = this.formGroup.controls.signingDate.value
                this.hiringData.startingDate = this.formGroup.controls.startingDate.value
                this.hiringData.salaryState = this.formGroup.controls.salaryState.value
                this.hiringData.salaryAmount = this.formGroup.controls.salaryText.value
                this.hiringData.adminNote = this.formGroup.controls.notes.value

                this.snackbar.open("Vertragsinformationen erfolgreich gespeichert!", null, {duration: 2000})
                this.sideSheetRef.sheetClosed.next(result)
            }
            this.updateHiringLoading = false
        })
    }

}
