import {LabelData} from "./label-data"
import {BookingState} from "./booking-state"

export class CompanyBookingData {
    applicationsCount: number = null;
    company = new LabelData();
    createdAt: (Date | null) = null;
    id: number = null;
    position = new LabelData();
    price: number = null;
    size: number = null;
    state: BookingState = null;
}