import {BookingState} from "./booking-state"
import {LabelData} from "./label-data"

export class CompanyInvoiceData {
    amount: number = null;
    applicationCount: number | null = null;
    bookingState: BookingState | null = null;
    company = new LabelData();
    date: (Date | null) = null;
    dtype: string = null;
    id: number = null;
    positions: LabelData[] | null = null;
}