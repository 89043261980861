<mat-form-field class="w-100" color="primary" [ngClass]="dropdownClass" [appearance]="appearance">
    <mat-label>{{placeholder}}</mat-label>
    <mat-select [formControl]="control"
                [multiple]="multiple"
                [required]="required"
                [disabled]="disabled"
                [disableOptionCentering]="disableOptionCentering">
        <div *ngIf="multiple" fxLayout="row" fxLayoutAlign="space-around center">
            <button mat-button class="toggle-all-button" (click)="unselectAll()" color="warn">Alle abwählen</button>
            <button mat-button class="toggle-all-button" (click)="selectAll()" color="primary">Alle Auswählen</button>
        </div>
        <mat-option *ngIf="!required && !multiple" [value]="null">
            <div style="width: 100%; text-align: center">- egal -</div>
        </mat-option>
        <mat-option *ngFor="let keyAccounter of keyAccounters" [value]="keyAccounter.adminRoleId">
            {{keyAccounter.adminName}}
        </mat-option>
    </mat-select>
</mat-form-field>
