import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProfileImageService} from '../../services/profile-image.service';
import {UtilityService} from '../../utils/utility.service';
import {
  AdminCompanyCreationData,
  AdminData,
  AdminRoleDisplayData,
  CompanyPricingConfigData,
  CompanyRole, LabelData
} from "../../generated/data";
import {AdminAdminResource, AdminCompanyResource, AdminPricingResource, AdminResource} from "../../generated/resources";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {Router} from "@angular/router";

@Component({
  selector: 'app-new-company-dialog',
  templateUrl: './new-company-dialog.component.html',
  styleUrls: ['./new-company-dialog.component.css']
})
export class NewCompanyDialogComponent implements OnInit {

  loading: boolean = false;
  pricingValid: boolean = false;
  companyCreationData: AdminCompanyCreationData = <AdminCompanyCreationData>{};
  keyAccounterData: AdminRoleDisplayData[] = [];
  form: FormGroup;
  pricing: CompanyPricingConfigData = <CompanyPricingConfigData>{}

  companyRoles: CompanyRole[] = ["EventManagement","ApplicationManagement"]

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private utilityService: UtilityService,
    private adminCompanyResource: AdminCompanyResource,
    private adminAdminResource: AdminAdminResource,
    private sideSheetRef: SideSheetRef,
    private adminPricingResource: AdminPricingResource
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      companyNameControl: [null, Validators.required],
      companyDescriptionControl: [null, Validators.required],
      companySizeControl: [null, Validators.required],
      companyWebsiteControl: null,
      companyKeyAccounterControl: [null, Validators.required],
      companyRoles: [null, Validators.required],
    })

    this.loadKeyAccounter();
  }

  loadKeyAccounter() {
    this.adminAdminResource.getAllKeyAccounter().then(keyAccounterData => {
      this.keyAccounterData = keyAccounterData;
    });
  }

  createCompany() {
    this.loading = true;

    this.companyCreationData.name = this.form.controls.companyNameControl.value
    this.companyCreationData.companySize = this.form.controls.companySizeControl.value
    this.companyCreationData.shortCompanyInfo = this.form.controls.companyDescriptionControl.value
    this.companyCreationData.website = this.form.controls.companyWebsiteControl.value
    this.companyCreationData.keyAccounterId = this.form.controls.companyKeyAccounterControl.value
    this.companyCreationData.companyRoles = this.form.controls.companyRoles.value

    this.adminCompanyResource.createCompany(this.companyCreationData).then(company => {
      this.pricing.company = <LabelData>{name: company.name, id: company.id}
      this.adminPricingResource.createOrUpdatePricing(this.pricing).then(result => {
        this.router.navigate([`companies/${company.id}`])
        this.sideSheetRef.sheetClosed.next();
      })


    });
  }
}
