import {Component, Input, OnInit} from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'invoice-data-display',
  templateUrl: './invoice-data-display.component.html',
  styleUrls: ['./invoice-data-display.component.scss']
})
export class InvoiceDataDisplayComponent implements OnInit {
  @Input() data: string
  @Input() label: string
  @Input() alternative?: string
  @Input() onButtonClick?: () => void;
  constructor(
      private clipboard: Clipboard,
      private snackbar: MatSnackBar,
  ) { }

  ngOnInit(): void {
  }

  copyValueToClipboard() {
    if (!this.data || this.data === '')
      return;

    this.clipboard.copy(this.data);
    this.snackbar.open(this.data + ", wurde kopiert!", null, {duration: 2000})
  }
}
