import {CoachingData} from "./coaching-data"
import {CoachingTypeData} from "./coaching-type-data"
import {DegreeType} from "./degree-type"
import {MatchingState} from "./matching-state"
import {MlpApplicationData} from "./mlp-application-data"
import {TalentLocationPreferenceData} from "./talent-location-preference-data"

export class ConsultantTalentProfileData {
    coaching = new CoachingData();
    coachingTypes: CoachingTypeData[] = null;
    createdAt: (Date | null) = null;
    cvId: number | null = null;
    degreeType: DegreeType | null = null;
    deleted: boolean = null;
    emailAddress: string = null;
    endDate: (Date | null) = null;
    firstName: string = null;
    funnelState: string | null = null;
    id: number = null;
    lastLogin: (Date | null) = null;
    lastName: string | null = null;
    markedAsLost: boolean = null;
    matchingState: MatchingState = null;
    mlpApplicationsData: MlpApplicationData[] = null;
    phoneNumber: string | null = null;
    preferredLocations = new TalentLocationPreferenceData();
    profilePictureId: number | null = null;
    showPrimeLevel: boolean = null;
    subject: string | null = null;
    talentMarkedAsHired: boolean = null;
    talentRated: boolean = null;
    talentUnreachable: boolean = null;
    university: string | null = null;
}