import {PositionContentSplitData} from "./position-content-split-data"
import {EmploymentPositionState} from "./employment-position-state"
import {BookingData} from "./booking-data"
import {EmploymentCategory} from "./employment-category"
import {LocationOptionsData} from "./location-options-data"
import {AssignedRecruiterData} from "./assigned-recruiter-data"

export class CompanyEmploymentPositionData {
    availableSlots: number = null;
    bmsReference: string | null = null;
    companyAlias: string | null = null;
    content: PositionContentSplitData | null = null;
    currentBooking: BookingData | null = null;
    employmentCategory: EmploymentCategory = null;
    employmentPositionShortDescription: string | null = null;
    id: number | null = null;
    internTitle: string = null;
    locations = new LocationOptionsData();
    professionId: number | null = null;
    recruiters: AssignedRecruiterData[] = null;
    showCompany: boolean = null;
    startingDate: (Date | null) = null;
    state: EmploymentPositionState = null;
    title: string = null;
    webLink: string | null = null;
    weeklyHoursFrom: number | null = null;
    weeklyHoursTo: number | null = null;
    yearlySalaryFrom: number | null = null;
    yearlySalaryTo: number | null = null;
}