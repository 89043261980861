<mat-form-field color="primary" [ngClass]="dropdownClass" [appearance]="appearance">
    <mat-label>{{ placeholder }}</mat-label>
    <button mat-icon-button matSuffix (click)="control.setValue(null); $event.stopPropagation()">
        <mat-icon>backspace</mat-icon>
    </button>
    <mat-select [formControl]="control"
                [disabled]="disabled"
                [disableOptionCentering]="disableOptionCentering">
        <mat-option>
            <ngx-mat-select-search [formControl]="filterControl"></ngx-mat-select-search>
        </mat-option>
        <mat-option (click)="createNewBooking()">
            <div style="width: 100%; text-align: center">(neue Buchung erstellen)</div>
        </mat-option>
        <mat-option *ngFor="let booking of bookings" [value]="booking.id">
            {{ booking.title }} ({{booking.state}})
        </mat-option>
    </mat-select>
    <mat-hint>{{hint}}</mat-hint>
</mat-form-field>
