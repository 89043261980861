import {Component, Input, OnInit} from '@angular/core';
import {AdminEmploymentPositionData, BookingData, EmploymentPositionBookingsData} from "../../../generated/data";
import {FormControl, FormGroup} from "@angular/forms";
import {AdminEmploymentPositionResource} from "../../../generated/resources";
import {UtilityService} from "../../../utils/utility.service";

@Component({
  selector: 'position-bookings',
  templateUrl: './position-bookings.component.html',
  styleUrls: ['./position-bookings.component.scss']
})
export class PositionBookingsComponent implements OnInit {
  constructor(
    private adminEmploymentPositionResource: AdminEmploymentPositionResource,
    private utilityService: UtilityService,
  ) {}

  @Input() position: AdminEmploymentPositionData;

  booking?: BookingData;
  loading: boolean = false;

  form = new FormGroup({
    currentBookingId: new FormControl(),
  });

  ngOnInit() {
    this.form.controls['currentBookingId'].setValue(this.position.currentBookingId);
  }

  save() {
    const data: EmploymentPositionBookingsData = {
      currentBookingId: this.form.controls['currentBookingId'].value,
    };

    this.loading = true;

    this.adminEmploymentPositionResource.updateBookings(data, this.position.id).then(() => {
      this.utilityService.showSnackBar("Buchungen gespeichert!");
    }).finally(() => {
      this.loading = false;
    })
  }
}
