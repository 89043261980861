import {LabelData} from "./label-data"
import {PlatformApplicationMinimalData} from "./platform-application-minimal-data"
import {CompanyPricingMode} from "./company-pricing-mode"
import {PositionMinStatusData} from "./position-min-status-data"
import {BookingState} from "./booking-state"

export class BookingData {
    applications: PlatformApplicationMinimalData[] | null = null;
    company = new LabelData();
    companyPricing: CompanyPricingMode | null = null;
    createdAt: (Date | null) = null;
    createdFromSubId: number | null = null;
    id: number | null = null;
    keyAccounter: LabelData | null = null;
    positions: PositionMinStatusData[] = null;
    price: number = null;
    resupply: number = null;
    size: number = null;
    state: BookingState | null = null;
    title: string = null;
}