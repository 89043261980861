import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'companyRole'
})
export class CompanyRolePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'EventManagement':
        return 'Event Übersicht';
      case 'ApplicationManagement':
        return 'Bewerber Übersichten';
    }
  }
}
