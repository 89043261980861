<div class="standard-container" fxLayout="column">
  <ng-template mat-tab-label>Bewerbungen</ng-template>

  <div *ngIf="applicationStateCounts" class="state-bar" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.withdrawnCount}}</div>
      <div class="state">Zurückgezogen</div>
    </div>
    <div class="state-box" fxLayout="column"
         fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.failedCount}}</div>
      <div class="state">Gescheitert</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.deniedCount}}</div>
      <div class="state">Abgelehnt</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.pendingCount}}</div>
      <div class="state">Offen</div>
    </div>

    <div class="state-box" fxLayout="column"
         fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.grantedCount}}</div>
      <div class="state">Bestätigt</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.interestingCount}}</div>
      <div class="state">Interessant</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.appProcessEnterCount}}</div>
      <div class="state">In Bewerbung</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.contractSentCount}}</div>
      <div class="state">Vertrag zugeschickt</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationStateCounts.hiredCount}}</div>
      <div class="state">Eingestellt</div>
    </div>

    <div class="state-box" fxLayout="column" fxLayoutAlign="space-around center">
      <div class="count">{{applicationCount}}</div>
      <div class="state">Gesamt</div>
    </div>
  </div>

  <div>
    <loading-spinner [loadingBool]="loading">
      <table mat-table [dataSource]="dataSource" class="w-100">

        <ng-container matColumnDef="creationDate">
          <th mat-header-cell *matHeaderCellDef>Datum</th>
          <td mat-cell *matCellDef="let row"> {{row.creationDate | date: 'dd.MM.yy'}}</td>
        </ng-container>

        <ng-container matColumnDef="talentName">
          <th mat-header-cell *matHeaderCellDef>Talent</th>
          <td mat-cell *matCellDef="let row">{{row.talentName}}</td>
        </ng-container>

        <ng-container matColumnDef="talentEmail">
          <th mat-header-cell *matHeaderCellDef>Talent</th>
          <td mat-cell *matCellDef="let row">{{row.talentEmailAddress}}</td>
        </ng-container>

        <ng-container matColumnDef="positionTitle">
          <th mat-header-cell *matHeaderCellDef>Stelle</th>
          <td mat-cell *matCellDef="let row">{{row.position.internTitle | sliceStringLength: 100}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let row" matTooltip="{{row.state | requestState}}">
            <i class="mdi mdi-timer-sand yellow" *ngIf="row.state == 'Pending'"></i>
            <i class="mdi mdi-star blue" *ngIf="row.state == 'Interesting'"></i>
            <i class="mdi mdi-briefcase blue" *ngIf="row.state == 'AppProcessEnter'"></i>
            <i class="mdi mdi-file-move blue" *ngIf="row.state == 'ContractSent'"></i>
            <i class="mdi mdi-briefcase-check blue" *ngIf="row.state == 'Hired'"></i>
            <i class="mdi mdi-check green" *ngIf="row.state == 'Granted'"></i>
            <i class="mdi mdi-close red"
               *ngIf="row.state == 'Denied' || row.state == 'Withdrawn' || row.state == 'Failed'"></i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
  </div>
</div>
