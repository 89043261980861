import {AccountInfoData} from "./account-info-data"
import {CompanyRole} from "./company-role"
import {OnboardingStep} from "./onboarding-step"

export class RecruiterAccountInfoData extends AccountInfoData {
    allowedRoles: CompanyRole[] = null;
    companyInactive: boolean = null;
    companyName: string = null;
    fullName: string = null;
    onboardingCompleted: boolean = null;
    onboardingStep: OnboardingStep | null = null;
    privacyOptIn: boolean = null;
}