import {LabelData} from "./label-data"
import {CompanyPricingMode} from "./company-pricing-mode"

export class CompanyPricingConfigData {
    comment: string | null = null;
    company = new LabelData();
    discountFirstInvoice: number = null;
    hiringAmount: number = null;
    hiringPercentage: number = null;
    id: number | null = null;
    mode: CompanyPricingMode = null;
}