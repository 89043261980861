import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormControl} from "@angular/forms";
import {AdminCompanyData, AdminRoleDisplayData} from "../../../generated/data";
import {AdminAdminResource, AdminCompanyResource} from "../../../generated/resources";
import {debounceTime} from "rxjs/internal/operators";

@Component({
  selector: 'key-accounter-dropdown',
  templateUrl: './key-accounter-dropdown.component.html',
  styleUrls: ['./key-accounter-dropdown.component.scss']
})
export class KeyAccounterDropdownComponent implements OnInit {
  @Input() placeholder: string = ''
  @Input() control: AbstractControl
  @Input() appearance: string = 'standard'
  @Input() multiple: boolean = true
  @Input() dropdownClass: string
  @Input() disableOptionCentering: boolean = true
  @Input() required: boolean = true
  @Input() disabled: boolean = false

  keyAccounters: AdminRoleDisplayData[] = [];

  constructor(
      private adminAdminResource: AdminAdminResource
  ) {
  }

  ngOnInit() {
    this.loadKeyAccounter();
  }


  loadKeyAccounter() {
    this.adminAdminResource.getAllKeyAccounter().then(keyAccounter => {
          this.keyAccounters = keyAccounter;
        }
    )
  }

  selectAll() {
    this.control.setValue(this.keyAccounters.map(o => o.adminRoleId))
  }

  unselectAll() {
    this.control.setValue([])
  }
}
