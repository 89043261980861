<div id="mainwrapper">
    <div class="mat-elevation-z4" fxLayout="row" id="navbar" fxLayoutAlign="space-between center">

        <div fxLayout="row" fxLayoutAlign="start center">
            <button *ngIf="authService.isAuthenticated()" mat-icon-button (click)="navOpen = !navOpen"><i
                    class="mdi mdi-menu"></i>
            </button>

            <img src="{{getImageSource()}}" id="logo-desktop"
                 [ngStyle]="{'margin-left': authService.isAuthenticated() ? '10px' : '110px' }"
                 [ngStyle.lt-md]="{'margin-left': authService.isAuthenticated() ? '5px' : '20px' }"
                 routerLink="/">

        </div>

        <div class="search-wrapper"
             fxLayout="row"
             fxLayoutAlign="center center"
             (click)="showSearchResults = true"
             (clickOutside)="showSearchResults = false">

            <input id="search-input" placeholder="Talent suchen" [formControl]="talentControl"
                   (focusin)="inputFocused=true" (blur)="endFocus()" autocomplete="off" #searchInput>

            <span>
                <mat-progress-spinner
                        id="input-progress-spinner"
                        *ngIf="loadingTalents"
                        color="primary"
                        diameter="18"
                        strokeWidth="2"
                        mode="indeterminate">
                </mat-progress-spinner>
                <i class="mdi mdi-close"
                   (click)="resetSearchInput()"
                   *ngIf="talentControl.value && talentControl.value.toString().length > 0">
                </i>
                    </span>

            <span class="input-icon"><i class="mdi mdi-magnify"></i></span>

            <div [ngClass]="{'fly-in': inputFocused}" class="mat-elevation-z6 width-100" id="search-list">
                <div class="search-element width-100" fxLayout="row" fxLayoutAlign="space-between center" *ngFor="let talent of talentsData">
                    <div class="info-element m-right40" style="color: black">{{talent.firstName}} {{talent.lastName}}</div>
                    <div class="info-element m-right40" style="color: black">{{talent.emailAddress}}</div>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-icon-button matTooltip="Profil öffnen" (click)="openTalentProfile(talent.id)">
                            <i class="mdi mdi-account"></i>
                        </button>
                    </div>
                </div>
                <div fxLayout="column" fxLayoutAlign="start center" class="no-item layout-padding">
                    <div class="m-bottom15" *ngIf="showNoItemInfo">Es wurde kein Talent gefunden</div>
                </div>
            </div>
        </div>

        <mat-menu #accountMenu="matMenu">
            <button mat-menu-item [routerLink]="getAccounterLink()"><i
                    class="mdi mdi-account-key"></i> Account
            </button>
            <button mat-menu-item (click)="logout()"><i class="mdi mdi-logout"></i> Abmelden</button>
        </mat-menu>

        <button fxLayout="row" fxLayoutAlign="start center" mat-button
                *ngIf="authService.isAuthenticated()"
                [matMenuTriggerFor]="accountMenu" fxHide.lt-md
                style="font-size: 18px;">
            <i class="mdi mdi-account-circle"></i>
            {{authService.getAccountInfo()?.firstName}}
            {{authService.getAccountInfo()?.lastName}}
        </button>
        <button fxLayout="row" fxLayoutAlign="start center" mat-icon-button
                [matMenuTriggerFor]="accountMenu" fxHide fxShow.lt-md
                style="font-size: 18px;">
            <i class="mdi mdi-account-circle"></i>
        </button>

    </div>

    <mat-sidenav-container id="side-nav-container">
        <mat-sidenav class="mat-elevation-z4" #snav [mode]="mobileQuery.matches ? 'over' : 'side'" [opened]="navOpen"
                     *ngIf="authService.isAuthenticated()">

            <mat-list fxLayout="column" id="navlist" class="no-padding">

                <div class="mat-list-heading">Allgemein</div>
                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/talents"
                               (click)="setTabTitle('Talente')"
                               [ngClass]="{'active': router.url === '/talents'}">
                    <i class="mdi mdi-rocket"></i>Talente
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/companies"
                               (click)="setTabTitle('Unternehmen')"
                               [ngClass]="{'active': router.url.includes('/companies') || router.url.includes('/companies/')}">
                    <i class="mdi mdi-domain"></i>Unternehmen
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center"
                               (click)="setTabTitle('Stellen')"
                               routerLink="/positions"
                               [ngClass]="{'active': router.url.includes('/positions')}">
                    <i class="mdi  mdi-briefcase-check"></i>Stellen
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/accounts"
                               (click)="setTabTitle('Zugänge')"
                               [ngClass]="{'active': router.url === '/accounts'}">
                    <i class="mdi mdi-account"></i>Admins
                </mat-list-item>

                <div class="mat-list-heading">Controlling</div>
                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/controlling/acquisition"
                               [ngClass]="{'active': router.url === '/controlling/acquisition'}"
                               (click)="setTabTitle('Anmeldestatistik')">
                    <i class="mdi mdi-account-check"></i>Acquisition
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/controlling/sales"
                               [ngClass]="{'active': router.url === '/controlling/sales'}"
                               (click)="setTabTitle('Vertriebscontrolling')">
                    <i class="mdi mdi-briefcase-outline"></i>Sales
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/applications"
                               (click)="setTabTitle('Bewerbungen')"
                               [ngClass]="{'active': router.url === '/applications'}">
                    <i class="mdi mdi-clock"></i>Bewerbungen
                </mat-list-item>

                <div class="mat-list-heading">Vertrieb</div>
                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/hirings"
                               [ngClass]="{'active': router.url === '/hirings'}"
                               (click)="setTabTitle('Einstellungen')">
                    <i class="mdi mdi-account-plus"></i>Einstellungen
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/bookings"
                               [ngClass]="{'active': router.url === '/bookings'}"
                               (click)="setTabTitle('Buchungen')">
                    <i class="mdi mdi-hand-pointing-right"></i>Buchungen
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/invoices"
                               [ngClass]="{'active': router.url === '/invoices'}"
                               (click)="setTabTitle('Abrechnungsboard')">
                    <i class="mdi mdi-cash-multiple"></i>Abrechnungen
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/webinar"
                               [ngClass]="{'active': router.url === '/webinar'}"
                               (click)="setTabTitle('Webinare')">
                    <i class="mdi mdi-cast-education"></i>Webinare
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center"
                               (click)="setTabTitle('Beraterverwaltung')"
                               routerLink="/consultants"
                               [ngClass]="{'active': router.url === '/consultants/'}">
                    <i class="mdi mdi-account-group"></i>Karrierecoaches
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center"
                               (click)="setTabTitle('Unternehmens Profile')"
                               routerLink="/companyProfiles"
                               [ngClass]="{'active': router.url === '/companyProfiles'}">
                    <i class="mdi mdi-card-account-details-outline"></i>Unternehmensprofile
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center"
                               (click)="setTabTitle('Demosystem')"
                               routerLink="/demo"
                               [ngClass]="{'active': router.url === '/demo'}">
                    <i class="mdi mdi-eye-outline"></i>Demosystem
                </mat-list-item>

                <div class="mat-list-heading">Kundenbetreuung</div>
                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/customers/applications"
                               [ngClass]="{'active': router.url === '/customers/applications'}"
                               (click)="setTabTitle('Kundenbetreuung')">
                    <i class="mdi mdi-filter"></i>Bewerbungsfunnel
                </mat-list-item>

                <div class="mat-list-heading">Content</div>
                <mat-list-item fxLayout="row" fxLayoutAlign="start center"
                               (click)="setTabTitle('LandingPages')"
                               routerLink="/landingpages"
                               [ngClass]="{'active': router.url === '/landingpages/'}">
                    <i class="mdi mdi-page-layout-body"></i>Landingpages
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center"
                               (click)="setTabTitle('Events')"
                               routerLink="/events"
                               [ngClass]="{'active': router.url === '/events'}">
                    <i class="mdi mdi-camera-party-mode"></i>Events
                </mat-list-item>

                <div class="mat-list-heading">Matching</div>
                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/feedcontrol"
                               [ngClass]="{'active': router.url === '/feedcontrol'}"
                               (click)="setTabTitle('Feedcontrol')">
                    <i class="mdi mdi-ballot"></i>Feedcontrol
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/matching/configreview"
                               [ngClass]="{'active': router.url === '/matching/configreview'}"
                               (click)="setTabTitle('Matchingconfig Reviiew')">
                    <i class="mdi mdi-file-find"></i>Config-Review
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/mapping-optimization"
                               (click)="setTabTitle('Mapping-Optimierung')"
                               [ngClass]="{'active': router.url === '/mapping-optimization'}">
                    <i class="mdi mdi-file-tree"></i>Mapping-Optimierung
                </mat-list-item>

                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/fieldOfActivityEditor"
                               (click)="setTabTitle('Tätigkeitsfeld-Qulifikation Editor')"
                               [ngClass]="{'active': router.url === '/fieldOfActivityEditor'}">
                    <i class="mdi mdi-file-tree"></i>Tätigkeitsfeld-Qulifikation
                </mat-list-item>

                <!--                <mat-list-item fxLayout="row" fxLayoutAlign="start center" routerLink="/subject-mapping"-->
                <!--                               (click)="setTabTitle('Subject-Mapping')"-->
                <!--                               [ngClass]="{'active': router.url === '/subject-mapping'}">-->
                <!--                    <i class="mdi mdi-file-tree"></i>Subject-Mapping-->
                <!--                </mat-list-item>-->

            </mat-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <router-outlet></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>

</div>
