import {LabelData} from "./label-data"
import {AdminPositionMatchingConfigData} from "./admin-position-matching-config-data"
import {LocationOptionsData} from "./location-options-data"
import {EmploymentPositionState} from "./employment-position-state"

export class AdminPositionMatchingConfigDisplayData {
    configData = new AdminPositionMatchingConfigData();
    location = new LocationOptionsData();
    numPrimeFilters: number = null;
    numSpecialTargetingFilters: number = null;
    position = new LabelData();
    state: EmploymentPositionState = null;
}