import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
    SearchableProfessionDropdownComponent
} from "../../utils/dropdowns/searchable-profession-dropdown/searchable-profession-dropdown.component";
import {PositionInformationComponent} from "./position-information/position-information.component";
import {AdminEmploymentPositionData, ProfessionData} from "../../generated/data";
import {FormControl} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UtilityService} from "../../utils/utility.service";
import {ActivatedRoute, Router} from "@angular/router";
import {
    AdminEmploymentPositionResource,
    AdminPositionContentSplitResource,
    AdminProfessionResource,
    AdminResource,
    BenefitResource,
} from "../../generated/resources";
import {MatDialog} from "@angular/material/dialog";
import {AdminComponent} from "../../admin.component";
import {Title} from "@angular/platform-browser";
import {environment} from "../../../environments/environment";
import {debounceTime} from "rxjs/internal/operators";
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {ConfirmDialogComponent} from "../../utils/confirm-dialog/confirm-dialog.component";
import {
    PositionPrivacyDisclaimerDialogComponent
} from "../position-privacy-disclaimer-dialog/position-privacy-disclaimer-dialog.component";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-position-details-dialog',
    templateUrl: './position-details-dialog.component.html',
    styleUrls: ['./position-details-dialog.component.scss']
})
export class PositionDetailsDialogComponent implements OnInit, OnDestroy {

    @ViewChild('professionDropdown') professionDropdown: SearchableProfessionDropdownComponent
    @ViewChild('positionInfoComp') positionInfoComp: PositionInformationComponent
    @ViewChild('positionContentComp') positionContentComp: PositionInformationComponent

    apiUrl: String;

    employmentPositionData = <AdminEmploymentPositionData>{};
    positionId: number;

    tabIndex: number = 0;
    loading = false;
    positionValid: boolean = false

    page: number = 0;
    size: number = 10;
    totalElements: number;
    totalPages: number;
    activeSplitCount: number;

    updatePositionLoading: boolean = false
    loadingAddSplit: boolean = false

    isThirdPartyPositionControl: FormControl = new FormControl(false);
    isThirdPartyAnonymousControl: FormControl = new FormControl(false);

    locationModeControl: FormControl = new FormControl()

    federalStatesControl: FormControl = new FormControl()
    citiesControl: FormControl = new FormControl()

    professionControl: FormControl = new FormControl()
    matchingProfessions: ProfessionData[] = [];
    professionSuggestionLoading: boolean = false

    titleControl: FormControl = new FormControl();

    constructor(
        private snackBar: MatSnackBar,
        private utilityService: UtilityService,
        private route: ActivatedRoute,
        private router: Router,
        private adminEmploymentPositionResource: AdminEmploymentPositionResource,
        private adminResource: AdminResource,
        private dialog: MatDialog,
        private adminComponent: AdminComponent,
        private benefitResource: BenefitResource,
        private titleService: Title,
        private professionResource: AdminProfessionResource,
        public sideSheetRef: SideSheetRef,
        private employmentPositionResource: AdminEmploymentPositionResource,
        private adminPositionContentSplitResource: AdminPositionContentSplitResource
    ) {
    }

    ngOnDestroy(): void {
        this.titleControlSub?.unsubscribe()
    }

    addSplitFromOldSplit(splitId: number) {
        this.loadingAddSplit = true
        this.adminPositionContentSplitResource.copyContentSplit(splitId).then(result => {
            this.employmentPositionData.contents.push(result)
            this.calcActiveSplits()
            this.loadingAddSplit = false
        })
    }

    addSplit() {
        this.loadingAddSplit = true
        this.adminPositionContentSplitResource.createNewContentSplit({positionId: this.positionId}).then(result => {
        this.employmentPositionData.contents.push(result)
            this.calcActiveSplits()
            this.loadingAddSplit = false
    })
    }

    ngOnInit() {
        this.apiUrl = environment.apiUrlMain
        this.loading = true;

        this.load();
        this.loading = false;
    }

    loadProfessionSuggestions() {
        this.professionSuggestionLoading = true

        this.professionResource.getBestMatchingProfessionForPositionTitle({title: this.employmentPositionData.internTitle}).then(professions => {
            this.matchingProfessions = professions
            this.professionSuggestionLoading = false
        })
    }

    removeSplit(id: number) {
        let i = this.employmentPositionData.contents.findIndex(i => i.id == id)
        this.employmentPositionData.contents.splice(i, 1)
    }

    calcActiveSplits(): number {
        this.activeSplitCount = this.employmentPositionData.contents.filter(s => s.active).length
        return this.activeSplitCount
    }

    setValidation(event) {
        this.positionValid = event
    }

    titleControlSub: Subscription
    load(reloadComponent: boolean = false) {
        this.loading = true;

        this.adminEmploymentPositionResource.getSingleEmploymentPositionById(this.positionId).then(position => {
            // position.contents.sort((n1,n2) => n1.id - n2.id)

            this.employmentPositionData = position;
            this.calcActiveSplits()
            this.isThirdPartyPositionControl.setValue(position.isThirdPartyPosition)
            this.isThirdPartyAnonymousControl.setValue(!position.showCompany)

            this.titleControl.setValue(position.internTitle);
            this.loadProfessionSuggestions()
            this.titleControlSub = this.titleControl.valueChanges.pipe(debounceTime(500)).subscribe(titleValue => {
                this.loadProfessionSuggestions()
            })

            this.locationModeControl.setValue(position.locations.mode);
            this.federalStatesControl.setValue(position.locations.federalStateIds);
            this.citiesControl.setValue(position.locations.cityIds);

            if (position.professionId == null) {
                this.professionSuggestionLoading = true
                this.professionResource.getBestMatchingProfessionForPositionTitle({title: this.employmentPositionData.internTitle}).then(profession => {
                    this.matchingProfessions = profession
                    this.professionSuggestionLoading = false
                })
            }
            this.professionControl.setValue(position.professionId);
            this.titleService.setTitle(this.employmentPositionData.internTitle);
            this.loading = false;

            if (reloadComponent) {
                this.positionInfoComp.ngOnInit();
                this.positionContentComp.ngOnInit()
            }
        });
    }

    routeToPreview() {
        window.open(environment.publicFrontendUrl + '/position-details/' + this.positionId, '_blank')
    }

    updatePosition() {
        this.updatePositionLoading = true;

        this.employmentPositionResource.updatePosition(this.employmentPositionData, this.positionId).then(result => {
            this.utilityService.showSnackBar('Stelle bearbeitet!');
            this.updatePositionLoading = false;
        })

    }



    deletePosition() {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: 'Stelle löschen',
                message: 'Möchtest du die Stelle wirklich löschen?',
                confirmButtonText: 'Löschen',
                confirmButtonColor: 'cancel',
                onConfirm: () => this.employmentPositionResource.deletePosition(this.positionId)
            },
            panelClass: 'custom-dialog-container'
        });

        let sub = dialogRef.afterClosed().subscribe(deleted => {
            if (deleted) this.utilityService.showSnackBar('Erfolgreich gelöscht');
            sub.unsubscribe()
        });
    }

    openPrivacyDisclaimerDialog() {
        this.dialog.open(PositionPrivacyDisclaimerDialogComponent, {
            data: this.positionId
        })
    }
}
