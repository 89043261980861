<div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
    <i matTooltip="{{application.state | requestState}}" class="mdi mdi-timer-sand yellow" *ngIf="application.state == 'Sent'"></i>
    <i matTooltip="{{application.state | requestState}}" class="mdi mdi-star blue" *ngIf="application.state == 'Interesting'"></i>
    <i matTooltip="{{application.state | requestState}}" class="mdi mdi-briefcase blue" *ngIf="application.state == 'AppProcessEnter'"></i>
    <i matTooltip="{{application.state | requestState}}" class="mdi mdi-briefcase-check blue" *ngIf="application.state == 'Hired'"></i>
    <i matTooltip="{{application.state | requestState}}" class="mdi mdi-check green" *ngIf="application.state == 'Granted'"></i>
    <i matTooltip="{{application.state | requestState}}" class="mdi mdi-close red"
       *ngIf="application.state == 'Denied' || application.state == 'Withdrawn' || application.state == 'Failed'"></i>
    <i style="font-size: 18px"
       matTooltip="Zu dieser Bewerbung wurde angegeben, dass das Talent eingestellt wurde"
       class="green mdi mdi-account-multiple-plus"
       *ngIf="application.applicationMarkedAsHired">

    </i>
</div>
