import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AdminPositionMatchingConfigData, FieldOfActivityRelationData, LabelData,
  PositionProfessionFieldRelationData, ProfessionFieldQualification
} from "../../generated/data";
import {FormControl} from "@angular/forms";
import {AdminProfessionResource} from "../../generated/resources";
import { debounceTime } from 'rxjs/operators';
import {SideSheetRef} from "../../utils/side-sheet/sideSheetRef";
import {MatTableDataSource} from "@angular/material/table";

@Component({
  selector: 'app-profession-fields-config-sheet',
  templateUrl: './profession-field-config-sheet.component.html',
  styleUrls: ['./profession-field-config-sheet.component.scss']
})
export class ProfessionFieldConfigSheetComponent implements OnInit {

  //Inputs
  position: LabelData //nullable
  config: AdminPositionMatchingConfigData; //nullable

  dataSource = new MatTableDataSource<PositionProfessionFieldRelationData>();

  searchControl = new FormControl('')

  recommendedFields: FieldOfActivityRelationData[] =[]
  fields: LabelData[] =[]

  constructor(private adminProfessionResource: AdminProfessionResource, private sideSheetRef:SideSheetRef ) { }

  ngOnInit(): void {

    //load recommendations
  if(this.config.profession.id){
    this.adminProfessionResource.getFieldsForProfession(this.config.profession.id).then(res => {
      this.recommendedFields = res;
  })
}


    this.searchControl.valueChanges
        .pipe(debounceTime(500))
        .subscribe(newValue => {

          if(newValue.length > 0) {
            this.load(newValue)
          }else{
            this.fields = []
          }
        })

    this.refreshDataSource()
  }

  load(searchQuery: string){
      this.adminProfessionResource.getProfessionFields({searchTerm: searchQuery}).then(res => {
        this.fields = res;
      })
  }

  updateQualification(relation: PositionProfessionFieldRelationData, newQualification: ProfessionFieldQualification){
    relation.qualification = newQualification;
    this.refreshDataSource();
  }


  addField(element: LabelData){
    if(this.config.positionProfessionFieldRelations.find(r => r.field.id == element.id) != null) return

    this.config.positionProfessionFieldRelations.push({
      field: element,
      qualification: 'Normal'
    })
    this.refreshDataSource();
  }

  removeField(fieldId: number){
    this.config.positionProfessionFieldRelations.splice(
        this.config.positionProfessionFieldRelations.indexOf(this.config.positionProfessionFieldRelations.find(r => r.field.id == fieldId))
    )
    this.refreshDataSource();
  }

  refreshDataSource(){
    this.dataSource.data = this.config.positionProfessionFieldRelations;
  }

  applyAndClose(){
    this.sideSheetRef.sheetClosed.next(this.config)
  }
}
