import {Component, Input, OnInit} from '@angular/core';
import {AdminApplicationData} from "../../generated/data";

@Component({
  selector: 'application-state',
  templateUrl: './application-state.component.html',
  styleUrls: ['./application-state.component.scss']
})
export class ApplicationStateComponent implements OnInit {

  @Input() application: AdminApplicationData

  constructor() { }

  ngOnInit(): void {
  }

}
