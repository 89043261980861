<dialog-header dialogHeadline="Neues Unternehmen erstellen"></dialog-header>

<div class="dialog-content">
  <form [formGroup]="form" fxLayout="column">
    <mat-form-field class="form-field">
      <input matInput placeholder="Firmenname" type="text" formControlName="companyNameControl">
    </mat-form-field>

    <mat-form-field class="form-field">
      <textarea matInput placeholder="Kurzinfo (max. 30 Zeichen)" rows="3" formControlName="companyDescriptionControl" maxlength="30"></textarea>
    </mat-form-field>

    <mat-form-field class="form-field">
      <mat-select formControlName="companySizeControl" placeholder="Unternehmensgröße">
        <mat-option value="Small">1 - 50 Mitarbeiter</mat-option>
        <mat-option value="Medium">50 - 500 Mitarbeiter</mat-option>
        <mat-option value="Big">mehr als 500 Mitarbeiter</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-field">
      <input matInput placeholder="Webseite" type="text" formControlName="companyWebsiteControl">
    </mat-form-field>

    <mat-form-field>
      <mat-select formControlName="companyKeyAccounterControl" placeholder="Key Account Manager">
        <mat-option *ngFor="let keyAccounter of keyAccounterData" [value]="keyAccounter.adminRoleId">
          {{keyAccounter.adminName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Welche Bereiche kann das Unternehmen sehen?</mat-label>
      <mat-select [multiple]="true" formControlName="companyRoles">
        <mat-option *ngFor="let role of companyRoles" [value]="role">{{role | companyRole}}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>

  <app-company-pricing-content [(pricing)]="pricing" (valid)="pricingValid = $event"></app-company-pricing-content>

</div>

<div fxLayout="row" fxLayoutAlign="end start" class="width-100">
  <loading-button buttonClass="form-field mat-primary"
                  [raised]="false"
                  [loadingBool]="loading"
                  [disabledCondition]="!form.valid || !pricingValid"
                  (clickEvent)="createCompany()">Speichern
  </loading-button>
</div>
