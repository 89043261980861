import {ApplicationGrantStrategy} from "./application-grant-strategy"
import {UploadedFileData} from "./uploaded-file-data"
import {CompanyInvoiceInformationData} from "./company-invoice-information-data"
import {CompanyRole} from "./company-role"

export class AdminCompanyData {
    applicationGrantStrategy: ApplicationGrantStrategy = null;
    banner: UploadedFileData | null = null;
    companyInvoiceInformation = new CompanyInvoiceInformationData();
    companyRoles: CompanyRole[] = null;
    consideredForReverseRecruiting: boolean = null;
    creationDate: (Date | null) = null;
    googleJobPosting: boolean = null;
    id: number = null;
    isUniwunderApiCustomer: boolean = null;
    logo: UploadedFileData | null = null;
    name: string = null;
    primeCompany: boolean = null;
    recruitersCount: number = null;
    reverseRecruitingCount: number = null;
    searchEnabledForApiPositions: boolean = null;
    shortCompanyInfo: string | null = null;
    showProfile: boolean = null;
    uniwunderApiUrl: string | null = null;
    website: string | null = null;
    yearlyRecruitings: number | null = null;
}